import { Button, Form } from 'react-admin';
import {
	Button as Btn,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import WrapTextIcon from '@mui/icons-material/WrapText';
import SetSelectorsForm from '../../shops/edit/SetSelectorsForm';

function SetSelectors({ data }: { data: any }) {
	const [openDialog, setOpenDialog] = useState(false);

	return (
		<>
			<Button
				sx={{ color: '#00baff' }}
				label='Neue Selektoren suchen'
				onClick={() => setOpenDialog(true)}
			>
				<WrapTextIcon />
			</Button>
			<Dialog fullWidth={true} maxWidth={false} open={openDialog}>
				<DialogTitle>Neue Selektoren suchen & ersetzen</DialogTitle>
				<DialogContent
					sx={{
						'& .MuiFormControl-root': { width: '100%' }
					}}
				>
					<Form>
						<SetSelectorsForm close={() => setOpenDialog(false)} />
					</Form>
				</DialogContent>
				<DialogActions>
					<Btn
						variant={'contained'}
						autoFocus
						onClick={() => setOpenDialog(false)}
					>
						Schließen
					</Btn>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default SetSelectors;
