import React from 'react';
import {
	List,
	Datagrid,
	TextField,
	DateField,
	TextInput,
	Filter,
	FunctionField,
	Pagination as DefaultPagination
} from "react-admin";
import { calculate7DayAvg } from "../utils/speedtestUtils";

const CustomPagination = (props: any) => (
	<DefaultPagination rowsPerPageOptions={[25]} {...props} />
);

export const ClientList = (props: any) => {
	return (
		<List {...props} filters={<ClientsFilter />} pagination={<CustomPagination />} perPage={25}>
			<Datagrid rowClick="show" bulkActionButtons={false}>
				<TextField source="name" label="Name" />
				<TextField source="location" label="Standort" />
				<TextField source="router" label="Router" />
				<FunctionField
					label="Durchschnittliche Geschwindigkeit (7 Tage)"
					render={(record: { speedtest: any }) => `${calculate7DayAvg(record.speedtest)} Mbit/s`}
				/>
				<DateField source="updatedAt" showTime label="Letztes Update" />
			</Datagrid>
		</List>
	);
};

const ClientsFilter = (props: any) => (
	<Filter {...props}>
		<TextInput label="Name" source="name" alwaysOn />
		<TextInput label="Standort" source="location" alwaysOn />
		<TextInput label="Router" source="router" alwaysOn />
	</Filter>
);
