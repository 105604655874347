import * as React from 'react';
import { Create, Form } from 'react-admin';
import { WizardForm } from './WizardForm';

const ShopsCreate = () => {
	return (
		<Create title={'Shops Create Wizard'}>
			<Form>
				<WizardForm />
			</Form>
		</Create>
	);
};

export default ShopsCreate;
