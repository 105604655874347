import * as React from 'react';
import {
	Box,
	Typography,
	Button,
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportIcon from '@mui/icons-material/Report';
import axios from 'axios';
import { useEffect, useState } from 'react';
import ImageGrid from './imageList';
import LoadingDialog from '../../utils/LoadingDialog';

//Dto class
class ProductImagesCandidatesDto {
	itemId: number;
	itemName: string;
	manufacturer: string;
	imageUrls: string[];
	foundUrl: string;
	noUrlFound: boolean;
	width: number | null;
	height: number | null;

	constructor(
		itemId: number,
		itemName: string,
		manufacturer: string,
		imageUrls: string[],
		foundUrl: string,
		noUrlFound: boolean,
		width: number | null,
		height: number | null,
	) {
		this.itemId = itemId;
		this.itemName = itemName;
		this.manufacturer = manufacturer;
		this.imageUrls = imageUrls;
		this.foundUrl = foundUrl;
		this.noUrlFound = noUrlFound;
		this.width = width;
		this.height = height;
	}
}

const ImageCandidate: React.FC = () => {
	const [item, setItem] = useState<{ itemId: number, itemName: string, manufacturer: string, imageUrls: string[], foundUrl: string, noUrlFound: boolean} | null>(null);
	const [selectedUrl, setSelectedUrl] = useState<string>('');
	const [open, setOpen] = useState(false);
	const [loadingOpen, setLoadingOpen] = useState(false);

	useEffect(() => {
		fetchNextItem()
	}, []);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleUrlChange = (value: string) => {
		setSelectedUrl(value);
	};

	const getImageDimensions = (url: string): Promise<{ width: number, height: number }> => {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.onload = () => {
				resolve({ width: img.width, height: img.height });
			};
			img.onerror = (error) => {
				reject(error);
			};
			img.src = url;
		});
	};

	const  handleTranslationProcess = async(instruction: string) => {
		if (!item) return;
		if(instruction === 'remove')
		{
			axios.post(process.env.REACT_APP_HUB_API_URL + '/images/no-candidate-found', item, {withCredentials: true})
				.then(response => {
					fetchNextItem();
				})
				.catch(error => {
					console.error(`Error processing item with instruction "${instruction}":`, error);
				});
		}
		else if(instruction === 'take')
		{
			const dims = await getImageDimensions(selectedUrl);
			const result = new ProductImagesCandidatesDto(
				item.itemId,
				item.itemName,
				item.manufacturer,
				item.imageUrls,
				selectedUrl,
				false,
				dims?.width ? dims.width : null,
				dims?.height ? dims.height : null,
			)
			axios.post(process.env.REACT_APP_HUB_API_URL + '/images/take-candidate', result, {withCredentials: true})
				.then(response => {
					fetchNextItem();
				})
				.catch(error => {
					console.error(`Error processing item with instruction "${instruction}":`, error);
				});
		}
		else if(instruction === 'error')
		{
			axios.post(process.env.REACT_APP_HUB_API_URL + '/images/save-problem', item, {withCredentials: true})
				.then(response => {
					fetchNextItem();
				})
				.catch(error => {
					console.error(`Error processing item with instruction "${instruction}":`, error);
				});
		}
	};

	const fetchNextItem = () => {
		setLoadingOpen(true);
		axios.get(process.env.REACT_APP_HUB_API_URL + '/images/get-next-candidate', {withCredentials: true})
			.then(response => {
				setItem(response.data);
			})
			.catch(error => {
				console.error('Error fetching the next item:', error);
			});
		setLoadingOpen(false);
	};

	const handleMarkProcessed = () => handleTranslationProcess('take');
	const handleRemoveItem = () => handleTranslationProcess('remove');
	const handleErrorItem = () => handleTranslationProcess('error');

	return (
		<Grid sx={{
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			gap: 2,
			padding: 2,
		}}>
			<Box sx={{
				width: '80%',
				justifyContent: 'center',
			}}>
				<Typography variant="h4" sx={{ textAlign: 'center' }}>{item?.itemName ? item.itemName : 'Leer'}</Typography>
				<Typography variant="body1" sx={{ textAlign: 'center' }}>Hersteller: {item?.manufacturer ? item.manufacturer : 'Leer'}</Typography>
				<ImageGrid imageUrls={item?.imageUrls? item.imageUrls : []} selectedUrl={selectedUrl} onCheckboxChange={handleUrlChange} />
			</Box>

			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>{"Image Candidate Erklärung"}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						In diesem Frontend werden Bilder für Produkte angezeigt, die durch die Prosoom-AI
						nicht zugewiesen werden konnten.
						Passt keines der Bilder und auf den Bildern ist ein anderes Produkt zu sehen, als
						der Name + Marke, der oben angezeigt wird, dann drückt man den Button "Falsches Item melden".
						Ist ein passendes Bild dabei, drückt man die Checkbox unter dem Bild und anschließend
						den Button "Bild übernehmen". Sind nur Platzhalter oder schlechte Bilder da, drückt man
						auf den Button "Kein passendes Bild".
						Viel Spaß :)
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary" autoFocus>
						Schließen
					</Button>
				</DialogActions>
			</Dialog>

			<LoadingDialog open={loadingOpen} />

			<Grid container sx={{
				width: '80%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
				<Button variant="contained" startIcon={<InfoIcon />} sx={{
					padding: '8px 16px',
					margin: '4px',
					marginRight: 'auto',
				}} onClick={handleClickOpen}>Hilfe</Button>
				<Box sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flex: '1 30px',
				}}>
					<Button variant="contained" startIcon={<ReportIcon />} sx={{
						padding: '8px 16px',
						margin: '4px',
					}} onClick={handleErrorItem}>Falsches Item melden</Button>

					<Button variant="contained" endIcon={<DeleteIcon />} sx={{
						padding: '8px 16px',
						margin: '4px',
					}} onClick={handleRemoveItem}>Kein passendes Bild</Button>

					<Button variant="contained" endIcon={<CheckCircleIcon />} sx={{
						padding: '8px 16px',
						margin: '4px',
					}} onClick={handleMarkProcessed}>Bild übernehmen</Button>
				</Box>
			</Grid>
		</Grid>
	);
};

export default ImageCandidate;