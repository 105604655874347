import * as React from 'react';
import { Admin, Resource, fetchUtils, CustomRoutes } from 'react-admin';
import { Route } from "react-router-dom";
import ShopsList from './components/shops/ShopsList';
import ShopsEdit from './components/shops/edit/ShopsEdit';
import ShopsCreate from './components/shops/create/ShopsCreate';
import ShopsShow from './components/shops/show/ShopsShow';
import Dashboard from './components/dashboard/Dashboard';
import StoreIcon from '@mui/icons-material/Store';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import BugReportIcon from '@mui/icons-material/BugReport';
import { ClientList } from './components/clients/ClientsList';
import { ClientEdit } from './components/clients/ClientEdit';
import crudProvider from 'ra-data-nestjsx-crud';
import LoginPage from './components/auth/loginPage';
import { DockerContainersList } from './components/docker-container/DockerContainersList';
import { Icon } from '@mui/material';
import authProvider from './components/auth/authProvider';
import ItemTranslation from './components/item/generated-translation/ItemTranslation';
import MyLayout from './layout/Layout';
import ItemGenerated from './components/item/generated-item/itemGenerated';
import ImageProblem from './components/image/image-problem/imageProblem';
import ImageSuggested from './components/image/image-suggested/imageSuggested';
import ImageUpload from './components/image/image-upload/imageUpload';
import CompanySuggested from './components/company/companySuggested';
import ImageCandidate from './components/image/image-candidates/imageCandidates';
import { ShopsMarketplaceList } from "./components/shopsMarketplace/shopsMarketplaceList";
import { ShopsMarketplaceEdit } from "./components/shopsMarketplace/shopsMarketplaceEdit";
import { ShopsMarketplaceCreate } from "./components/shopsMarketplace/shopsMarketplaceCreate";
import ClientShow from "./components/clients/ClientShow";

const DockerIcon = () => (
	<Icon>
		<img alt={'icon'} src={'/docker-icon_small.svg'} />
	</Icon>
);
const App = () => {
	const fetchJson = (url: string, options: fetchUtils.Options = {}) => {
		const customHeaders = (options.headers ||
			new Headers({
				Accept: 'application/json'
			})) as Headers;
		//customHeaders.set("X-Custom-Header", "foobar");
		options.headers = customHeaders;
		options.credentials = 'include';
		return fetchUtils.fetchJson(url, options);
	};

	const apiUrl = process.env.REACT_APP_HUB_API_URL
		? process.env.REACT_APP_HUB_API_URL
		: '';
	// @ts-ignore
	const dataProvider = crudProvider(apiUrl, fetchJson);

	// Because of  (Polyglot.transformPhrase expects argument #1 to be string) BUG
	// -> https://github.com/marmelab/react-admin/issues/5727
	const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', {
		allowMissing: true,
		onMissingKey: (key: any, _: any, __: any) => key
	});

	return (
		<Admin
			loginPage={LoginPage}
			layout={MyLayout}
			authProvider={authProvider}
			title='Data Management Interface (DMI)'
			dashboard={Dashboard}
			dataProvider={dataProvider}
			i18nProvider={i18nProvider}
			requireAuth={true}
		>
			<Resource
				name='shops'
				options={{ label: 'Shops' }}
				show={ShopsShow}
				list={ShopsList}
				edit={ShopsEdit}
				create={ShopsCreate}
				icon={StoreIcon}
			/>
			<Resource
				name="shops-marketplace"
				options={{label: 'Shops Marketplace'}}
				list={ShopsMarketplaceList}
				edit={ShopsMarketplaceEdit}
				create={ShopsMarketplaceCreate}
				icon={StoreIcon}
			/>
			<Resource
				name={'clients'}
				list={ClientList}
				show={ClientShow}
				edit={ClientEdit}
				icon={BugReportIcon}
			/>
			<Resource
				name={'docker-container'}
				options={{ label: 'Docker Container' }}
				list={DockerContainersList}
				icon={DockerIcon}
			/>
			<CustomRoutes>
				<Route path="/item-translation" element={<ItemTranslation />} />
			</CustomRoutes>
			<CustomRoutes>
				<Route path="/item-generation" element={<ItemGenerated />} />
			</CustomRoutes>
			<CustomRoutes>
				<Route path="/image-problem" element={<ImageProblem />} />
			</CustomRoutes>
			<CustomRoutes>
				<Route path="/image-suggestion" element={<ImageSuggested />} />
			</CustomRoutes>
			<CustomRoutes>
				<Route path="/image-candidate" element={<ImageCandidate />} />
			</CustomRoutes>
			<CustomRoutes>
				<Route path="/image-upload" element={<ImageUpload />} />
			</CustomRoutes>
			<CustomRoutes>
				<Route path="/company-suggested" element={<CompanySuggested />} />
			</CustomRoutes>

			{/*<Resource*/}
			{/*    name="selectors-template"*/}
			{/*    options={{label: 'Selectors Template'}}*/}
			{/*    list={SelectorsTemplateList}*/}
			{/*    show={SelectorsTemplateShow}*/}
			{/*    edit={SelectorsTemplateEdit}*/}
			{/*    create={SelectorsTemplateCreate}*/}
			{/*    icon={SelectAllIcon}*/}
			{/*/>*/}
		</Admin>
	);
};

export default App;
