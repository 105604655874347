import * as React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useRedirect, useNotify } from 'react-admin';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import { Box, Button, CircularProgress } from '@mui/material';

export const NextPrevious = ({ data }: { data: any }) => {
	const [shopsIds, setShopsIds] = useState<Number[]>([]);
	const redirect = useRedirect();
	const notify = useNotify();
	useEffect(() => {
		async function getIds() {
			axios
				.get(process.env.REACT_APP_HUB_API_URL + '/shops/get_shop_ids', { withCredentials: true })
				.then((response) => {
					setShopsIds(response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		}
		getIds();
	}, []);

	function next() {
		const index = shopsIds.findIndex((element) => element === data.id);
		const nextCrawler = shopsIds[index + 1];
		if (data.id === shopsIds[shopsIds.length - 1]) {
			notify('Mehr gibts leider noch nicht...', { type: 'warning' });
		} else {
			redirect(`/shops/${nextCrawler}/show`);
		}
	}

	function previous() {
		const index = shopsIds.findIndex((element) => element === data.id);
		const previousCrawler = shopsIds[index - 1];
		if (index === 0) {
			notify('Es geht nicht weiter zurück...', { type: 'warning' });
		} else {
			redirect(`/shops/${previousCrawler}/show`);
		}
	}

	return shopsIds.length === 0 ? (
		<CircularProgress
			style={{
				position: 'absolute',
				left: '0px',
				color: 'black',
				marginLeft: '100px'
			}}
			size={30}
		/>
	) : (
		<div style={{ position: 'absolute', left: '0px' }}>
			<Button
				size='small'
				onClick={previous}
				style={{ marginRight: '20px' }}
				startIcon={<NavigateBeforeOutlinedIcon />}
			>
				Vorheriger
			</Button>
			<Button
				size='small'
				onClick={next}
				endIcon={<NavigateNextOutlinedIcon />}
			>
				Nächster
			</Button>
		</div>
	);
};
