import * as React from 'react';
import axios from 'axios';
import { Title, Loading } from 'react-admin';
import { useState, useEffect } from 'react';
import ShopsCard from './ShopsCard';
import RefreshButton from './RefreshData';
import UrlsCard from './UrlsCard';
import ClientsCard from './ClientsCard';
import { Box, Grid } from '@mui/material';

function Dashboard() {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			const reloaded = localStorage.getItem('reloaded');

			if (!reloaded) {
				localStorage.setItem('reloaded', 'true');
				console.log('reload_because_no_authtoken');
				window.location.reload();
			}
			console.log('get dashboard data');

			axios
				.get(process.env.REACT_APP_HUB_API_URL + '/dashboards', { withCredentials: true })
				.then((response) => {
					setData(response.data);
					setLoading(false);
				})
				.catch((err) => console.error(err));
		}, 1000);
		return () => clearTimeout(timer);
	}, []);

	if (loading) return <Loading />;

	return (
		<div className='dashboard'>
			<Title title='Prosoom Administration' />
			<RefreshButton />
			<Box component='div'>
				<Grid container spacing={2}>
					<Grid item xs={12} lg={6}>
						<Grid container direction='column'>
							<ShopsCard
								data={data
									.filter((x: any) => x.category === 'shops')
									.sort(
										(a: any, b: any) =>
											a['sort'] - b['sort']
									)}
							/>
							<ClientsCard
								data={data.filter(
									(x: any) => x.category === 'clients'
								)}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Grid container direction='column'>
							<UrlsCard
								data={data
									.filter((x: any) => x.category === 'urls')
									.sort(
										(a: any, b: any) =>
											a['sort'] - b['sort']
									)}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</div>
	);
}

export default Dashboard;
