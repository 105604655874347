import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';

interface ImageOrPlaceholderProps {
	src?: string; // Optionaler Bild-Link
	alt?: string; // Optionaler Alt-Text
	height?: number; // Optionale Höhe des Bildes oder Platzhalters
}

const ImageOrPlaceholder: React.FC<ImageOrPlaceholderProps> = ({
																   src,
																   alt = 'Image',
																   height = 500,
															   }) => {
	const [naturalWidth, setNaturalWidth] = useState<number | null>(null);
	const [imageExists, setImageExists] = useState(true);
	const [loading, setLoading] = useState(false); // Zustand für die Ladeanimation

	useEffect(() => {
		if (src) {
			setLoading(true); // Ladeanimation starten
			const img = new Image();
			img.src = src;

			img.onload = () => {
				setNaturalWidth(img.width * (height! / img.height));
				setImageExists(true);
				setLoading(false); // Ladeanimation beenden
			};

			img.onerror = () => {
				setImageExists(false);
				setLoading(false); // Ladeanimation beenden
			};
		}
	}, [src, height]);

	if (!imageExists || !src) {
		return (
			<Box
				sx={{
					height: `${height}px`,
					width: 'auto',
					minWidth: 300,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: '#eee',
					border: '1px solid #ccc',
				}}
			>
				No Image
			</Box>
		);
	}

	if (loading) {
		return (
			<Box
				sx={{
					height: `${height}px`,
					width: 'auto',
					minWidth: 300,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: '#eee',
					border: '1px solid #ccc',
				}}
			>
				<CircularProgress /> {/* Anzeige der Ladeanimation */}
			</Box>
		);
	}

	return (
		<Box
			component="img"
			src={src}
			alt={alt}
			sx={{ height: `${height}px`, width: naturalWidth ? `${naturalWidth}px` : 'auto' }}
		/>
	);
};

export default ImageOrPlaceholder;
