import * as React from 'react';
import { SaveButton } from 'react-admin';
import { RequiredStep } from './RequiredStep';
import { ExpectedValuesStep } from './ExpectedValuesStep';
import { SelectorsStep } from './SelectorsStep';
import { OptionalsStep } from './OptionalsStep';
import { useFormContext } from 'react-hook-form';
import { SchemaSelectorFinderStep } from './SchemaSelectorFinderStep';
import {
	Box,
	Button,
	CircularProgress,
	Step,
	StepButton,
	StepLabel,
	Stepper
} from '@mui/material';

function getSteps() {
	return [
		'Details',
		' Selektoren-Finder',
		'Erwartete Werte',
		'CSS Selektoren'
	];
}

function getStepContent(step: number) {
	switch (step) {
		case 0:
			return <RequiredStep />;
		case 1:
			return <SchemaSelectorFinderStep />;
		case 2:
			return <ExpectedValuesStep />;
		case 3:
			return <SelectorsStep />;
		default:
			return 'Unknown step';
	}
}

export const WizardForm = () => {
	const [activeStep, setActiveStep] = React.useState(0);
	const [getSelectors, setGetSelectors] = React.useState(false);
	const steps = getSteps();

	const { getValues, setValue } = useFormContext();

	const handleNext = () => {
		let formData = getValues();
		console.log(formData);

		if (activeStep === 0) {
			if (!formData.productUrl || !formData.shopUrl) {
				alert(
					'Die Shop Url und/oder die Produkt Url müssen ausgefüllt sein'
				);
				return;
			}
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStep = (step: any) => () => {
		setActiveStep(step);
	};

	return (
		<>
			{getSelectors && (
				<Box
					sx={{
						position: 'absolute',
						height: '100%',
						width: '100%',
						backgroundColor: '#969696d4',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						zIndex: 2
					}}
				>
					<span
						style={{
							marginRight: '25px',
							color: 'white',
							fontSize: 'x-large'
						}}
					>
						Automatisch Selektoren bestimmen
					</span>
					<CircularProgress size={150} />
				</Box>
			)}
			<Box sx={{ '& > div': { px: 4 } }}>
				<Box py={2} mb={2} sx={{ backgroundColor: '#dedede' }}>
					<Stepper activeStep={activeStep} nonLinear>
						{steps.map((label, index) => {
							return (
								<Step key={label}>
									<StepButton
										color='inherit'
										onClick={handleStep(index)}
									>
										{label}
									</StepButton>
								</Step>
							);
						})}
					</Stepper>
				</Box>
				<Box>{getStepContent(activeStep)}</Box>
				<Box display={'flex'} justifyContent={'space-between'} py={4}>
					<Box>
						{activeStep > 0 && (
							<Button
								disabled={activeStep === 0}
								onClick={handleBack}
								sx={{ mr: 1 }}
							>
								Zurück
							</Button>
						)}
						{activeStep < getSteps().length - 1 && (
							<Button
								variant='contained'
								color='primary'
								onClick={handleNext}
							>
								{' '}
								Weiter{' '}
							</Button>
						)}
					</Box>
					<SaveButton
						label='Neuen Shop speichern'
						sx={{ backgroundColor: '#72ba4f' }}
					/>
				</Box>
			</Box>
		</>
	);
};
