import React, { useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { calculate7DayAvgPerDay, filterLast2Days } from "../../utils/speedtestUtils";

interface SpeedtestData {
    speed: number;
    createdAt: string;
}

interface SpeedtestGraphProps {
    data: SpeedtestData[];
}

const SpeedtestGraph: React.FC<SpeedtestGraphProps> = ({ data }) => {
    const [showLast7Days, setShowLast7Days] = useState(false);

    // Überprüfe, ob die Daten vorhanden sind, bevor du weiter machst
    if (!data || data.length === 0) {
        return <Typography variant="h6">Keine Daten vorhanden</Typography>;
    }

    const dataLast2Days = filterLast2Days(data);

    const formattedData = dataLast2Days.map(entry => ({
        ...entry,
        createdAt: new Date(entry.createdAt).toLocaleDateString(),
    }));

    const avgPerDayLast7Days = calculate7DayAvgPerDay(data).map(d => ({
        ...d,
        avgSpeed: Number(d.avgSpeed),
    }));

    const maxSpeed = showLast7Days
        ? Math.max(...avgPerDayLast7Days.map(d => d.avgSpeed))
        : Math.max(...formattedData.map(d => d.speed));

    const toggleGraph = () => {
        setShowLast7Days(prevShowGraph => !prevShowGraph);
    };

    return (
        <Box p={2}>
            <Typography variant="h6" gutterBottom>
                {showLast7Days ? " Ansicht: 7-Tage Durchschnitt" : " Ansicht: Letzte 2 Tage"}
            </Typography>

            <Box mb={2}>
                <Button variant="contained" color="primary" onClick={toggleGraph}>
                    {showLast7Days ? "Letzte 2 Tage anzeigen" : "7-Tage Durchschnitt anzeigen"}
                </Button>
            </Box>

            <Box mt={2}>
                <ResponsiveContainer width="100%" height={400}>
                    <LineChart
                        data={showLast7Days ? avgPerDayLast7Days : formattedData}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey={showLast7Days ? "date" : "createdAt"}
                            label={{ value: 'Date', position: 'insideBottomRight', offset: -5 }}
                        />
                        <YAxis
                            label={{ value: 'Speed (Mbit/s)', angle: -90, position: 'insideLeft' }}
                            domain={[0, maxSpeed + 20]}
                        />
                        <Tooltip />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey={showLast7Days ? "avgSpeed" : "speed"}
                            stroke="#8884d8"
                            activeDot={{ r: 8 }}
                            connectNulls={true}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </Box>
        </Box>
    );
};

export default SpeedtestGraph;
