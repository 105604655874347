import { AuthProvider } from 'react-admin';
import axios from 'axios';

let userId: number = 0;
let userDashUrl: string = '';
let companyId: number = 0;

const backendApi = process.env.REACT_APP_HUB_API_URL;
const requestConfig = { withCredentials: true };

const authProvider: AuthProvider = {
	async login({ password, username }): Promise<any> {
		try {
			const res = await axios.post(
				backendApi + '/auth/login',
				{
					email: username,
					password: password
				},
				requestConfig
			);
			return await Promise.resolve();
		} catch {
			return await Promise.reject();
		}
	},
	async logout(): Promise<void | false | string> {
		try {
			await axios.get(backendApi + '/auth/logout', requestConfig);
			return await Promise.resolve();
		} catch{
			console.log("error logout")
			return await Promise.resolve();
		}
	},
	async checkAuth(): Promise<void> {
		try {
			const res = await axios.get(
				backendApi + '/auth/check-auth',
				requestConfig
			);
			if (res.data?.['isAuthenticatedUser'] === false) {
				return await Promise.reject();
			} else {
				return await Promise.resolve();
			}
		} catch (e){
			return await Promise.reject();
		}
	},
	checkError(error: any): Promise<void> {
		console.log('error', error);
		// when the dataProvider returns an error, check if this is an authentication error
		// Der AuthGuard gibt 401 zurück bei falschen jwt
		const status = error?.status;
		// if (status === 401 || status === 403) {
		if (status === 401 || !status) {
			return Promise.reject();
		}
		// other error code (404, 500, etc): no need to log out
		return Promise.resolve();
	},
	async getPermissions(): Promise<any> {
		// try {
		//     const res = await axios.get(backendApi + '/users/permissions', requestConfig);
		//     console.log(res);
		//     if (res.data) {
		//         return await Promise.resolve(res.data);
		//     }else {
		//         return Promise.reject();
		//     }
		// } catch {
		//     return Promise.reject();
		// }
	},
	async getIdentity(): Promise<any> {
		try {
			const res = await axios.get(
				backendApi + '/auth/identity',
				requestConfig
			);
			//console.log(res);
			if (res.data) {
				return await Promise.resolve(res.data);
			} else {
				return Promise.reject();
			}
		} catch {
			return Promise.reject();
		}
	}
};

export default authProvider;
