import * as React from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import { useState } from 'react';
import { useNotify } from 'react-admin';
import { Button } from '@mui/material';

function RefreshButton() {
	const [loading, setLoading] = useState(false);
	const notify = useNotify();

	const refreshData = () => {
		setLoading(true);

		fetch(process.env.REACT_APP_HUB_API_URL + '/dashboards/refreshData')
			.then((response) => {
				notify('Success');
			})
			.catch((e) => {
				console.log(e);
				notify('Error!');
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Button
			variant='contained'
			sx={{ marginTop: '25px', float: 'right' }}
			onClick={refreshData}
			startIcon={<CachedIcon />}
			disabled={loading}
		>
			Refresh Data
		</Button>
	);
}

export default RefreshButton;
