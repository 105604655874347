import * as React from 'react';
import BugReportIcon from '@mui/icons-material/BugReport';
import { Card, CardContent, Grid, Typography } from '@mui/material';

function ClientsCard(props: any) {
	return (
		<Grid item xs={12} sx={{ position: 'relative', marginTop: '25px' }}>
			<BugReportIcon
				className={'iconDashboard'}
				style={{ background: '#F9AB18' }}
			/>
			<Card sx={{ paddingBottom: '10px' }}>
				<CardContent>
					<Typography
						color={'textSecondary'}
						variant='h4'
						align='center'
					>
						Clients
					</Typography>
					<Typography
						color={'textSecondary'}
						variant='h2'
						align='center'
						sx={{ mt: '25px' }}
					>
						{props.data['0'].value}
					</Typography>
				</CardContent>
			</Card>
		</Grid>
	);
}

export default ClientsCard;
