import axios from 'axios';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Grid,
	Typography
} from '@mui/material';
import SelectorsEdit from './SelectorsEdit';
import SelectorsNew from './SelectorsNew';
import React from 'react';
import { useNotify, useUpdate } from 'react-admin';
import { useRecordContext } from 'react-admin';
import ExpectedValuesNewEdit from './ExpectedValuesNewEdit';

function SetSelectorsForm(props: any) {
	const record = useRecordContext();
	const notify = useNotify();
	const [update, { isLoading, error }] = useUpdate();
	const { getValues, setValue } = useFormContext();
	const [isLoadingSchema, setIsLoadingSchema] = useState(false);
	const [isLoadingSelectors, setIsLoadingSelectors] = useState(false);
	const [originalRecord, setOriginalRecord] = useState({ ...record });
	const handleSearchSchema = () => {
		setIsLoadingSchema(true);
		const formData = getValues();

		axios
			.post(
				process.env.REACT_APP_HUB_API_URL +
					'/shops/get-expected-values-and-schema-selectors',
				{ formData: formData }	, { withCredentials: true }
			)
			.then((response) => {
				for (const property in response.data.selectors) {
					if (property == 'breadCrumbs') {
						if (formData['breadCrumbs'] == null)
							setValue(
								'newSelectorBreadcrumbs',
								response.data.selectors['breadCrumbs']
							);
					} else {
						const name =
							'newSelector' +
							property.charAt(0).toUpperCase() +
							property.slice(1);
						if (formData[name] == null)
							setValue(name, response.data.selectors[property]);
					}
				}
				for (const property in response.data.expectedResponses) {
					if (property == 'breadCrumbs') {
						setValue(
							'newExpectedBreadcrumbs',
							response.data.expectedResponses['breadCrumbs']
								?.toString()
								.slice(0, 255)
						);
					} else {
						setValue(
							'newExpected' +
								property.charAt(0).toUpperCase() +
								property.slice(1),
							response.data.expectedResponses[property]
								?.toString()
								.slice(0, 255)
						);
					}
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => setIsLoadingSchema(false));
	};

	const handleSearch = () => {
		setIsLoadingSelectors(true);
		const formData = getValues();

		axios
			.post(
				process.env.REACT_APP_HUB_API_URL +
					'/shops/get-selectors-by-expected-values',
				{ formData: formData }	, { withCredentials: true }
			)
			.then((response) => {
				for (const property in response.data) {
					if (property == 'breadCrumbs') {
						if (formData['breadCrumbs'] == null)
							setValue(
								'newSelectorBreadcrumbs',
								response.data['breadCrumbs']
									?.toString()
									.slice(0, 255)
							);
					} else {
						const name =
							'new' +
							property.charAt(0).toUpperCase() +
							property.slice(1);
						if (formData[name] == null)
							setValue(
								name,
								response.data[property]
									?.toString()
									.slice(0, 255)
							);
					}
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				setIsLoadingSelectors(false);
			});
	};

	const switchAllValues = () => {
		const formData = getValues();
		for (const key in formData) {
			if (key.startsWith('newSelector')) {
				const columnVal = getValues(key);
				if (columnVal) {
					const name = 's' + key.slice(4);
					setValue(name, columnVal);
				}
			}
			if (key.startsWith('newExpected')) {
				const columnVal = getValues(key);
				if (columnVal) {
					const name = 'e' + key.slice(4);
					setValue(name, columnVal);
				}
			}
		}
	};

	const handleSave = () => {
		const formData = getValues();
		debugger;
		update('shops', {
			id: formData.id,
			data: formData,
			previousData: record
		});
		props.close();
		notify('Neue Selektoren wurden gespeichert!', { type: 'success' });
	};

	return (
		<Box>
			<Box sx={{ backgroundColor: '#d9d9d9' }} p={1} my={2} pb={2}>
				<Box display={'flex'} justifyContent={'space-between'}>
					<Box display={'flex'} flexDirection={'column'}>
						<Box>
							<Button
								variant={'contained'}
								sx={{ mr: 2 }}
								onClick={handleSearchSchema}
								disabled={isLoadingSchema}
							>
								Schema Selektoren Suchen{' '}
								{isLoadingSchema && (
									<CircularProgress
										size={20}
										sx={{ ml: 2 }}
									/>
								)}
							</Button>
							<Button
								variant={'contained'}
								onClick={handleSearch}
								disabled={isLoadingSelectors}
							>
								CSS Selektoren Suchen{' '}
								{isLoadingSelectors && (
									<CircularProgress
										size={20}
										sx={{ ml: 2 }}
									/>
								)}
							</Button>
						</Box>
						<Typography variant={'subtitle2'}>
							<strong>Hinweis:</strong> Es werden nur nicht
							ausgefüllte Felder in der Spalte "Gefundene
							Selektoren" ersetzt
						</Typography>
					</Box>

					<Button
						variant={'contained'}
						color={'warning'}
						sx={{ ml: 2, height: 'fit-content' }}
						onClick={switchAllValues}
					>
						<strong>Alle gefundenen Selektoren übernehmen</strong>
					</Button>
					<Button
						variant={'contained'}
						color={'success'}
						sx={{ ml: 2, height: 'fit-content' }}
						onClick={handleSave}
						disabled={isLoading}
					>
						Neue Selektoren für diesen Shop speichern!{' '}
						{isLoading && (
							<CircularProgress size={20} sx={{ ml: 2 }} />
						)}
					</Button>
				</Box>
			</Box>
			<Divider sx={{ mb: 4, mt: 2 }} />
			<Box>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<SelectorsEdit label={'Aktuelle Selektoren'} />
					</Grid>
					<Grid item xs={4} sx={{ borderRight: '2px solid', pr: 2 }}>
						<SelectorsNew label={'Gefundene Selektoren'} />
					</Grid>
					<Grid item xs={4}>
						<ExpectedValuesNewEdit />
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}

export default SetSelectorsForm;
