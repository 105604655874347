import * as React from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { useState } from 'react';
import { Badge, Link } from '@mui/material';
import { useRecordContext } from 'react-admin';

const URLFieldWithCtoC = ({ label, source }: { label: any; source: any }) => {
	const [badgeContent, setBadgeContent] = useState(null);
	const record = useRecordContext();
	function clickIcon() {
		// @ts-ignore
		setBadgeContent('copied!');
	}

	return (
		<div>
			<div>
				<Link
					href={record[source]}
					target='_blank'
					style={{ paddingRight: '15px', fontSize: '15px' }}
				>
					{record[source]}
				</Link>
				<CopyToClipboard text={record[source]}>
					<Badge badgeContent={badgeContent} color='secondary'>
						<FileCopyOutlinedIcon
							onClick={clickIcon}
							className='UrlCtoCIcon'
						/>
					</Badge>
				</CopyToClipboard>
			</div>
		</div>
	);
};

URLFieldWithCtoC.propTypes = {
	label: PropTypes.string,
	record: PropTypes.object,
	source: PropTypes.string.isRequired
};

export default URLFieldWithCtoC;
