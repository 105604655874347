import * as React from 'react';
import { useRedirect } from 'react-admin';
import { useState } from 'react';
import { TextField } from '@mui/material';

export const GoToID = (alwaysOn: any) => {
	const [input, setInput] = useState('');

	const redirect = useRedirect();
	async function goToId() {
		redirect(`/shops/${input}/show`);
	}
	return (
		<TextField
			style={{ width: '110px' }}
			label='Go to ID ⏎'
			variant='filled'
			size='small'
			onChange={(e: any) => {
				setInput(e.target.value);
			}}
			onKeyDown={(e) => {
				if (e.key === 'Enter') {
					goToId();
				}
			}}
		/>
	);
};
