import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput } from 'react-admin';

export const DockerContainersList = (props: any) => {
	return (
		<List {...props} perPage={100} filters={<DockerContainerFilters />}>
			<Datagrid rowClick='edit' bulkActionButtons={false}>
				<TextField source='hostname' />
				<TextField source='containerId' label='Container ID' />
				<TextField source='image' />
				<TextField source='name' />
				<TextField source='status' />
			</Datagrid>
		</List>
	);
};

const DockerContainerFilters = (props: any) => {
	return (
		<Filter {...props}>
			<TextInput label='Hostname' source='hostname' alwaysOn />
			<TextInput label='Container ID' source='containerId' alwaysOn />
			<TextInput label='Image' source='image' alwaysOn />
			<TextInput label='Name' source='name' alwaysOn />
			<TextInput label='Status' source='status' alwaysOn />
		</Filter>
	);
};
