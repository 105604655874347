import * as React from 'react';
import {
	Box,
	Typography,
	Button,
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions, TextField,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import PreviewIcon from '@mui/icons-material/Preview';
import UploadIcon from '@mui/icons-material/Upload';
import SearchIcon from '@mui/icons-material/Search';
import ImageOrPlaceholder from '../../utils/ImageOrPlaceholder';
import axios from 'axios';
import { useState } from 'react';
import ErrorDialog from '../../utils/ErrorDialog';

const ImageUpload: React.FC = () => {
	const [item, setItem] = useState<{ itemId: number, itemName: string, imgUrl: string, manufacturer: string} | null>(null);
	const [open, setOpen] = useState(false);
	const [itemId, setNewItemId] = useState<string>('');
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [dialogHeader, setDialogHeader] = useState('');
	const [imgUrl, setImgUrl] = useState<string>('');
	const [tempImgUrl, setTempImgUrl] = useState<string>('');
	const [dimensions, setDimensions] = useState<{ width: number, height: number } | null>(null);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTempImgUrl(e.target.value);
	};

	const handleButtonClick = async () => {
		if(isValidUrl(tempImgUrl))
		{
			const dims = await getImageDimensions(tempImgUrl);
			setDimensions(dims);
			setImgUrl(tempImgUrl);
		}
		else
		{
			setErrorMessage('Bitte eine valide Bild URL eingeben.');
			setDialogHeader('Fehler');
			setErrorDialogOpen(true);
		}
	};
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleErrorDialogClose = () => {
		setErrorDialogOpen(false);
	};

	const getImageDimensions = (url: string): Promise<{ width: number, height: number }> => {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.onload = () => {
				resolve({ width: img.width, height: img.height });
			};
			img.onerror = (error) => {
				reject(error);
			};
			img.src = url;
		});
	};

	const isValidUrl = (urlString: string): boolean => {
		try {
			// Wenn die URL nur mit 'www' startet, fügen wir 'http://' hinzu
			if (urlString.startsWith('www')) {
				urlString = 'http://' + urlString;
			}
			const url = new URL(urlString);
			console.log(url)
			console.log(url.protocol)
			return url.protocol === "http:" || url.protocol === "https:";
		} catch (_) {
			return false;
		}
	};

	const searchItem = () => {
		const itemIdSearch = Number(itemId)
		if(!isNaN(itemIdSearch))
		{
			setImgUrl('');
			setTempImgUrl('');
			axios.post(process.env.REACT_APP_HUB_API_URL + '/images/get-item', {itemId: itemIdSearch}, {withCredentials: true})
				.then(response => {
					setItem(response.data);
				})
				.catch(error => {
					setErrorMessage('Fehler beim item id lookup: '+ error);
					setDialogHeader('Fehler');
					setErrorDialogOpen(true);
				});
		}
		else {
			setErrorMessage('Bitte eine valide Zahl bei der Item id eingeben.');
			setDialogHeader('Fehler');
			setErrorDialogOpen(true);
		}
	};

	const handleUpload = () => {
		if(isValidUrl(imgUrl))
		{
			const result = {
				itemId: itemId,
				imgUrl: imgUrl,
				width: dimensions?.width,
				height: dimensions?.height,
			}
			axios.post(process.env.REACT_APP_HUB_API_URL + '/images/upload-image', result, {withCredentials: true})
				.then(response => {
					setErrorMessage('Bild wurde erfolgreich hochgeladen.');
					setDialogHeader('Erfolg');
					setErrorDialogOpen(true);
					searchItem();
				})
				.catch(error => {
					setErrorMessage('Fehler beim image upload: '+ error);
					setDialogHeader('Fehler');
					setErrorDialogOpen(true);
				});
		}
		else {
			setErrorMessage('Bitte eine valide Bild URL eingeben.');
			setDialogHeader('Fehler');
			setErrorDialogOpen(true);
		}
	};

	return (
		<Grid
			container
			direction="column"
			alignItems="center"
			spacing={3}
			sx={{ padding: 2 }}
		>
			<Grid item container alignItems="center" justifyContent="center" spacing={2}>
				<Grid item>
					<Typography variant="body1">Item Id: </Typography>
				</Grid>
				<Grid item>
					<TextField
						label={'Item Id'}
						onChange={(e) => setNewItemId(e.target.value)}
					/>
				</Grid>
				<Grid item>
					<Button
						variant="contained"
						startIcon={<SearchIcon />}
						sx={{ padding: '8px 16px' }}
						onClick={searchItem}
					>
						Suchen
					</Button>
				</Grid>
			</Grid>

			<Grid item sx={{ width: '80%', textAlign: 'center' }}>
				<Typography variant="h4">
					{item?.itemName ? item.itemName : 'Leer'}
				</Typography>
				<Typography variant="body1">
					Hersteller: {item?.manufacturer ? item.manufacturer : 'Leer'}
				</Typography>
			</Grid>

			<Grid item container spacing={2} justifyContent="center">
				<Grid item xs={12} md={6} container direction="column" alignItems="center">
					<Typography variant="body1" sx={{ textAlign: 'center' }}>Bisheriges Bild</Typography>
					<Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
						<ImageOrPlaceholder src={item ? item.imgUrl : ''} height={500} alt="Img" />
					</Box>
				</Grid>
				<Grid item xs={12} md={6} container direction="column" alignItems="center">
					<Typography variant="body1" sx={{ textAlign: 'center' }}>Neues Bild</Typography>
					<Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
						<ImageOrPlaceholder src={imgUrl} height={500} alt="Img" />
					</Box>
				</Grid>
			</Grid>

			<Grid item container alignItems="center" justifyContent="center" spacing={2}>
				<Grid item>
					<Typography variant="body1">Neuer Bild URL: </Typography>
				</Grid>
				<Grid item>
					<TextField
						label={'Bild URL'}
						value={tempImgUrl}
						onChange={handleInputChange}
					/>
				</Grid>
				<Grid item>
					<Button
						variant="contained"
						startIcon={<PreviewIcon />}
						sx={{ padding: '8px 16px' }}
						onClick={handleButtonClick}
					>
						Vorschau
					</Button>
				</Grid>
			</Grid>

			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>{"Image Upload Erklärung"}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						In diesem Frontend können neue Produktbilder hochgeladen werden.
						Zuerst gibt man die Item Id ein und drückt auf den Button "Suchen".
						Anschließend wird das Item geladen und man kann unten die neue Bild Url eingeben.
						Drückt man nun auf "Vorschau" wird rechts das neue Produktbild angezeigt.
						Mit Upload läd man dieses nun noch und es sollte innerhalb kürzester Zeit in den
						Stammdaten und der Suche auftauchen.
						Viel Spaß :)
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary" autoFocus>
						Schließen
					</Button>
				</DialogActions>
			</Dialog>

			<Grid item container justifyContent="center" spacing={2} sx={{ width: '80%' }}>
				<Grid item>
					<Button
						variant="contained"
						startIcon={<InfoIcon />}
						sx={{ padding: '8px 16px' }}
						onClick={handleClickOpen}
					>
						Hilfe
					</Button>
				</Grid>
				<Grid item>
					<Button
						variant="contained"
						startIcon={<UploadIcon />}
						sx={{ padding: '8px 16px' }}
						onClick={handleUpload}
					>
						Upload
					</Button>
				</Grid>
			</Grid>

			<ErrorDialog
				open={errorDialogOpen}
				onClose={handleErrorDialogClose}
				errorMessage={errorMessage}
			 	header={dialogHeader}
			/>
		</Grid>
	);
};

export default ImageUpload;