import * as React from 'react';
import {
	TabbedShowLayout,
	Show,
	TextField,
	BooleanField,
	Tab,
	TopToolbar,
	EditButton,
	NumberField,
	SelectField,
	required,
	NumberInput,
	useRecordContext,
	BooleanInput
} from 'react-admin';
import TestShops from '../../btn/one/TestShops';
import FindShopSystem from '../../btn/one/FindShopSystem';
import SetSelectorsTemplate from '../../btn/one/SetSelectorsTemplate';
import CleanUrls from '../../btn/one/CleanUrls';
import URLFieldWithCtoC from './URLFieldWithCtoC';
import { ShopsShowAside } from './ShopsShowAside';
import { NextPrevious } from './NextPrevious';
import LabelMatchName from './LabelMatchName';
import { pznTypes } from '../pznTypes';
import OverwriteSelectors from './OverwriteSelectors';
import TestShopsCurl from '../../btn/one/TestShopsCurl';
import {
	Box,
	Divider,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@mui/material';
import JSONViewer from './JSONViewer';
import SetSelectors from '../../btn/one/SetSelectors';
import ShopLogo from './ShopLogo';

const ShopsShowAction = () => {
	const data = useRecordContext();
	return (
		<TopToolbar>
			<NextPrevious data={data} />
			{/* <OverwriteSelectors data={data}/>*/}
			{/* <SetSelectorsTemplate data={data}/>*/}
			<SetSelectors data={data} />
			<TestShops data={data} />
			<TestShopsCurl data={data} />
			{/*<FindShopSystem data={data}/>*/}
			<CleanUrls data={data} />
			<EditButton />
		</TopToolbar>
	);
};

const fields = [
	'name',
	'articleNumberOne',
	'articleNumberTwo',
	'articleNumberThree',
	'price',
	'currency',
	'manufacturer',
	'imgUrl',
	'stockStatus',
	'deliveryTime',
	'description',
	'amount1',
	'unit1',
	'amountUnit1',
	'amount2',
	'unit2',
	'amountUnit2',
	'ratingValue',
	'ratingCount',
	'category',
	'breadcrumbs',
	'srp',
	'normalizedPrice'
];

const ShopsShow = (props: any) => {
	return (
		<Show
			aside={<ShopsShowAside />}
			actions={<ShopsShowAction />}
			{...props}
		>
			<TabbedShowLayout>
				<Tab label='General'>
					<Logo/>
					<TextField source='id'/>
					<TextField source='priority'/>
					<SelectField
						source='pznType'
						label='PZN Type'
						choices={pznTypes}
					/>
					<NumberField
						source='nextRetryIn'
						label='Next retry in (Days)'
					/>
					<BooleanField source='status'/>
					<BooleanField source='topShop' label={'Top Shop'}/>
					<TextField label='Shop-System' source='shopSystem'/>
					<TextField source='shopUrlHostname' label='Hostname'/>
					<URLFieldWithCtoC source='shopUrl' label='Shop Url'/>
					<URLFieldWithCtoC source='productUrl' label='Product Url'/>
					{/*<URLFieldWithCtoC source="shopLogoUrl" label="Shop Logo Url"/>*/}
					<TextField
						label='Deny Parameter from URL'
						source='denyParam'
					/>
					<TextField label='Deny Path from URL' source='denyPath'/>
					<BooleanField source='removeHash' label='Remove #'/>
					<h5 style={{marginBottom: "0px"}}>Affiliate Einstellungen</h5>
					<TextField
						label='Affiliate Prefix URL'
						source='affiliatePrefix'
					/>
					<BooleanField source='ownShopAffiliateOnly' label='Affiliate Links nur für "eigener Shop"'/>
					<h5 style={{marginBottom: "0px"}}>Notizen</h5>
					<TextField label='Notes' multiline='true' source='note'/>
					<h5 style={{marginBottom: "0px"}}>Weitere Informationen </h5>
					<NumberField source='shippingCost' label='Versandkosten'/>
					<NumberField
						source='freeShippingFrom'
						label='Versandkostenfrei ab'
					/>
					<NumberField
						source='courierCosts'
						label='Botendienst/Kurierdienst Kosten'
					/>
				</Tab>
				<Tab label='JSON Test Response'>
					<JSONViewer/>
				</Tab>
				<Tab label='Selektoren'>
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Name</TableCell>
									<TableCell>Selektor</TableCell>
									<TableCell>Erwarteter Wert</TableCell>
									<TableCell>Response Wert</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{fields.map((row) => {
									const nameUppercase =
										row.charAt(0).toUpperCase() +
										row.slice(1);
									return (
										<TableRow
											key={row}
											sx={{
												'&:last-child td, &:last-child th':
													{ border: 0 }
											}}
										>
											<TableCell>
												<LabelMatchName
													label={nameUppercase}
													source={
														'match' + nameUppercase
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
													source={
														'selector' +
														nameUppercase
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
													source={
														'expected' +
														nameUppercase
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
													source={
														'responseSelector' +
														nameUppercase
													}
												/>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Tab>
				<Tab label='Onsite Search'>
					<BooleanField
						source='onsiteSearchStatus'
						label='Onsite Search Crawling aktiv'
					/>
					<TextField label='Search Url' source='onsiteSearchUrl' />
					<TextField label='Selektor' source='selectorOnsiteSearch' />
					<TextField label='Methode' source='onsiteSearchMethod' />
				</Tab>
				<Tab label='Shop-Logo'>
					<ShopLogo />
				</Tab>
			</TabbedShowLayout>
		</Show>
	);
};

function Logo() {
	const record = useRecordContext();
	return (
		<Box
			my={2}
			component={'img'}
			src={`https://prosoom-shop-logos.s3.eu-central-1.amazonaws.com/${record['id']}.webp`}
			maxHeight={200}
			maxWidth={200}
			alt={'Kein Logo vorhanden'}
		/>
	);
}

export default ShopsShow;
