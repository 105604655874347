import React from 'react';
import { Box, Checkbox, Typography, Grid } from '@mui/material';
import ImageOrPlaceholderGrid from './ImageOrPlaceholderGrid';

interface ImageGridProps {
	imageUrls: string[];
	selectedUrl: string;
	onCheckboxChange: (arg0: string) => void;
}

const ImageGrid: React.FC<ImageGridProps> = ({ imageUrls, selectedUrl, onCheckboxChange }) => {
	return (
		<Grid container spacing={2}>
			{imageUrls.map((url, index) => (
				<Grid item xs={12} sm={6} md={4} key={index}>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						textAlign="center"
						border={1}
						borderColor="grey.400"
						borderRadius={4}
						padding={2}
						sx={{
							width: '100%',
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center'
						}}
					>
						<Box sx={{
							width: '100%',
							height: 0,
							paddingBottom: '100%',
							position: 'relative'
						}}>
							<ImageOrPlaceholderGrid src={url} />
						</Box>
						<Typography
							variant="body2"
							sx={{
								marginTop: 2,
								wordBreak: 'break-all', // Ensure URLs break correctly
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								width: '100%',
								whiteSpace: 'nowrap'
							}}
							title={url} // Tooltip to show full URL on hover
						>
							{url}
						</Typography>
						<Checkbox
							checked={selectedUrl === url}
							onChange={() => onCheckboxChange(url)}
						/>
					</Box>
				</Grid>
			))}
		</Grid>
	);
};

export default ImageGrid;