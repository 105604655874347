import React, { useState } from 'react';
import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Divider,
	styled,
	Typography
} from '@mui/material';
import { useRecordContext } from 'react-admin';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useNotify } from 'react-admin';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from 'axios';

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1
});

function ShopLogo() {
	const record = useRecordContext();
	const notify = useNotify();
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [fileError, setFileError] = useState('');
	const [uploadingFile, setUploadingFile] = useState(false);
	const [key, setKey] = useState(0);
	const allowedValues = '.jpg, .jpeg, .png, .webp, .svg';

	const handleSelectFile = (event: any) => {
		if (event.target.files.length > 0) {
			setSelectedFile(null);
			setFileError('');
			console.log(event.target.files);
			if (event.target.files[0].size > 1048576) {
				setFileError('Datei zu groß. Maximal 1MB');
			} else {
				if (event.target.files[0]) {
					setSelectedFile(event.target.files[0]);
				}
			}
		}
	};

	const handleUploadFile = () => {
		setUploadingFile(true);
		const formData = new FormData();

		// @ts-ignore
		formData.append('file', selectedFile);
		// @ts-ignore
		formData.append('shopId', record['id']);

		axios
			.post(
				process.env.REACT_APP_HUB_API_URL + '/shops/update_shop_logo',
				formData	, { withCredentials: true }
			)
			.then(() => {
				notify('Neues Logo hochgeladen', { type: 'success' });
				setKey((k) => k + 1);
			})
			.catch((e) => {
				console.log(e);
				notify(e.response.data.message, { type: 'error' });
			})
			.finally(() => setUploadingFile(false));
	};

	return (
		<Box key={key}>
			<Typography variant={'h6'}>Aktuelles Logo</Typography>
			<Box
				my={2}
				component={'img'}
				src={`https://prosoom-shop-logos.s3.eu-central-1.amazonaws.com/${record['id']}.webp`}
				//src={`https://shop-logos.prosoom.com/${record["id"]}.webp`}
				//src={"https://picsum.photos/200"}
				maxHeight={100}
				alt={'Kein Logo vorhanden'}
			/>
			<Divider sx={{ mb: 2 }} />
			<Typography variant={'h6'}>Neues Logo hochladen</Typography>
			<Alert severity={'warning'} sx={{ width: 'fit-content', mt: 2 }}>
				Durch Caching des Logos in einem CDN wird beim Update des Logos
				das neue Logo mit Verzögerung angezeigt.
			</Alert>
			<ul>
				<li>Mindestauflösung 200px hoch</li>
				<li>
					Der Rand (weisser Bereich zwischen Logo und Kanten) sollte
					so klein wie möglich sein
				</li>
				<li>Erlaubte Formate sind {allowedValues}</li>
				<li>Maximale größe 1MB</li>
			</ul>
			<Box mb={2}>
				<Box
					sx={{
						border: '1px grey dashed',
						p: 2,
						my: 2,
						width: 'fit-content'
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Button
							component='label'
							variant='outlined'
							startIcon={<AttachFileIcon />}
							sx={{ mr: 1 }}
						>
							Auswählen
							<VisuallyHiddenInput
								type='file'
								accept={allowedValues}
								onChange={handleSelectFile}
							/>
						</Button>
						{selectedFile ? (
							<Typography variant={'body2'}>
								{selectedFile.name} (
								{(selectedFile.size / (1024 * 1024)).toFixed(2)}{' '}
								MB)
							</Typography>
						) : (
							<Typography variant={'body2'}>
								Kein Logo ausgewählt
							</Typography>
						)}
						{fileError.length > 0 && (
							<Typography variant={'body1'} color={'error'}>
								&nbsp;{fileError}
							</Typography>
						)}
					</Box>
				</Box>
				{selectedFile && (
					<Box my={2}>
						<Typography variant={'h6'}>Vorschau</Typography>
						<Typography variant={'subtitle2'}>
							Durch den grauen Rand wird die Dimension des Bildes
							dargestellt
						</Typography>
						<Box
							my={2}
							component={'img'}
							src={URL.createObjectURL(selectedFile)}
							maxHeight={500}
							maxWidth={500}
							border={'1px solid grey'}
							alt={'Kein Logo vorhanden'}
						/>
					</Box>
				)}
				<Button
					variant={'contained'}
					onClick={handleUploadFile}
					disabled={
						uploadingFile || fileError.length > 0 || !selectedFile
					}
					startIcon={<CloudUploadIcon />}
				>
					{uploadingFile && (
						<CircularProgress
							sx={{ alignSelf: 'center', mr: 1, color: 'white' }}
							size={20}
						/>
					)}
					Logo jetzt hochladen
				</Button>
			</Box>
		</Box>
	);
}

export default ShopLogo;
