const unsortedPznTypes = [
	{ id: 'af', name: 'Afghanistan' },
	{ id: 'al', name: 'Albanien' },
	{ id: 'dz', name: 'Algerien' },
	{ id: 'ad', name: 'Andorra' },
	{ id: 'ao', name: 'Angola' },
	{ id: 'ag', name: 'Antigua und Barbuda' },
	{ id: 'ar', name: 'Argentinien' },
	{ id: 'am', name: 'Armenien' },
	{ id: 'au', name: 'Australien' },
	{ id: 'at', name: 'Österreich' },
	{ id: 'az', name: 'Aserbaidschan' },
	{ id: 'bs', name: 'Bahamas' },
	{ id: 'bh', name: 'Bahrain' },
	{ id: 'bd', name: 'Bangladesch' },
	{ id: 'bb', name: 'Barbados' },
	{ id: 'by', name: 'Belarus' },
	{ id: 'be', name: 'Belgien' },
	{ id: 'bz', name: 'Belize' },
	{ id: 'bj', name: 'Benin' },
	{ id: 'bt', name: 'Bhutan' },
	{ id: 'bo', name: 'Bolivien' },
	{ id: 'ba', name: 'Bosnien und Herzegowina' },
	{ id: 'bw', name: 'Botsuana' },
	{ id: 'br', name: 'Brasilien' },
	{ id: 'bn', name: 'Brunei' },
	{ id: 'bg', name: 'Bulgarien' },
	{ id: 'bf', name: 'Burkina Faso' },
	{ id: 'bi', name: 'Burundi' },
	{ id: 'kh', name: 'Kambodscha' },
	{ id: 'cm', name: 'Kamerun' },
	{ id: 'ca', name: 'Kanada' },
	{ id: 'cv', name: 'Kap Verde' },
	{ id: 'cf', name: 'Zentralafrikanische Republik' },
	{ id: 'td', name: 'Tschad' },
	{ id: 'cl', name: 'Chile' },
	{ id: 'cn', name: 'China' },
	{ id: 'co', name: 'Kolumbien' },
	{ id: 'km', name: 'Komoren' },
	{ id: 'cd', name: 'Demokratische Republik Kongo' },
	{ id: 'cg', name: 'Republik Kongo' },
	{ id: 'cr', name: 'Costa Rica' },
	{ id: 'hr', name: 'Kroatien' },
	{ id: 'cu', name: 'Kuba' },
	{ id: 'cy', name: 'Zypern' },
	{ id: 'cz', name: 'Tschechien' },
	{ id: 'dk', name: 'Dänemark' },
	{ id: 'dj', name: 'Dschibuti' },
	{ id: 'dm', name: 'Dominica' },
	{ id: 'do', name: 'Dominikanische Republik' },
	{ id: 'ec', name: 'Ecuador' },
	{ id: 'eg', name: 'Ägypten' },
	{ id: 'sv', name: 'El Salvador' },
	{ id: 'gq', name: 'Äquatorialguinea' },
	{ id: 'er', name: 'Eritrea' },
	{ id: 'ee', name: 'Estland' },
	{ id: 'et', name: 'Äthiopien' },
	{ id: 'fj', name: 'Fidschi' },
	{ id: 'fi', name: 'Finnland' },
	{ id: 'fr', name: 'Frankreich' },
	{ id: 'ga', name: 'Gabun' },
	{ id: 'gm', name: 'Gambia' },
	{ id: 'ge', name: 'Georgien' },
	{ id: 'de', name: 'Deutschland' },
	{ id: 'gh', name: 'Ghana' },
	{ id: 'gr', name: 'Griechenland' },
	{ id: 'gd', name: 'Grenada' },
	{ id: 'gt', name: 'Guatemala' },
	{ id: 'gn', name: 'Guinea' },
	{ id: 'gw', name: 'Guinea-Bissau' },
	{ id: 'gy', name: 'Guyana' },
	{ id: 'ht', name: 'Haiti' },
	{ id: 'hn', name: 'Honduras' },
	{ id: 'hu', name: 'Ungarn' },
	{ id: 'is', name: 'Island' },
	{ id: 'in', name: 'Indien' },
	{ id: 'id', name: 'Indonesien' },
	{ id: 'ir', name: 'Iran' },
	{ id: 'iq', name: 'Irak' },
	{ id: 'ie', name: 'Irland' },
	{ id: 'il', name: 'Israel' },
	{ id: 'it', name: 'Italien' },
	{ id: 'jm', name: 'Jamaika' },
	{ id: 'jp', name: 'Japan' },
	{ id: 'jo', name: 'Jordanien' },
	{ id: 'kz', name: 'Kasachstan' },
	{ id: 'ke', name: 'Kenia' },
	{ id: 'ki', name: 'Kiribati' },
	{ id: 'kp', name: 'Nordkorea' },
	{ id: 'kr', name: 'Südkorea' },
	{ id: 'kw', name: 'Kuwait' },
	{ id: 'kg', name: 'Kirgisistan' },
	{ id: 'la', name: 'Laos' },
	{ id: 'lv', name: 'Lettland' },
	{ id: 'lb', name: 'Libanon' },
	{ id: 'ls', name: 'Lesotho' },
	{ id: 'lr', name: 'Liberia' },
	{ id: 'ly', name: 'Libyen' },
	{ id: 'li', name: 'Liechtenstein' },
	{ id: 'lt', name: 'Litauen' },
	{ id: 'lu', name: 'Luxemburg' },
	{ id: 'mk', name: 'Nordmazedonien' },
	{ id: 'mg', name: 'Madagaskar' },
	{ id: 'mw', name: 'Malawi' },
	{ id: 'my', name: 'Malaysia' },
	{ id: 'mv', name: 'Malediven' },
	{ id: 'ml', name: 'Mali' },
	{ id: 'mt', name: 'Malta' },
	{ id: 'mh', name: 'Marshallinseln' },
	{ id: 'mr', name: 'Mauretanien' },
	{ id: 'mu', name: 'Mauritius' },
	{ id: 'mx', name: 'Mexiko' },
	{ id: 'fm', name: 'Mikronesien' },
	{ id: 'md', name: 'Moldawien' },
	{ id: 'mc', name: 'Monaco' },
	{ id: 'mn', name: 'Mongolei' },
	{ id: 'me', name: 'Montenegro' },
	{ id: 'ma', name: 'Marokko' },
	{ id: 'mz', name: 'Mosambik' },
	{ id: 'mm', name: 'Myanmar' },
	{ id: 'na', name: 'Namibia' },
	{ id: 'nr', name: 'Nauru' },
	{ id: 'np', name: 'Nepal' },
	{ id: 'nl', name: 'Niederlande' },
	{ id: 'nz', name: 'Neuseeland' },
	{ id: 'ni', name: 'Nicaragua' },
	{ id: 'ne', name: 'Niger' },
	{ id: 'ng', name: 'Nigeria' },
	{ id: 'no', name: 'Norwegen' },
	{ id: 'om', name: 'Oman' },
	{ id: 'pk', name: 'Pakistan' },
	{ id: 'pw', name: 'Palau' },
	{ id: 'pa', name: 'Panama' },
	{ id: 'pg', name: 'Papua-Neuguinea' },
	{ id: 'py', name: 'Paraguay' },
	{ id: 'pe', name: 'Peru' },
	{ id: 'ph', name: 'Philippinen' },
	{ id: 'pl', name: 'Polen' },
	{ id: 'pt', name: 'Portugal' },
	{ id: 'qa', name: 'Katar' },
	{ id: 'ro', name: 'Rumänien' },
	{ id: 'ru', name: 'Russland' },
	{ id: 'rw', name: 'Ruanda' },
	{ id: 'kn', name: 'St. Kitts und Nevis' },
	{ id: 'lc', name: 'St. Lucia' },
	{ id: 'vc', name: 'St. Vincent und die Grenadinen' },
	{ id: 'ws', name: 'Samoa' },
	{ id: 'sm', name: 'San Marino' },
	{ id: 'st', name: 'São Tomé und Príncipe' },
	{ id: 'sa', name: 'Saudi-Arabien' },
	{ id: 'sn', name: 'Senegal' },
	{ id: 'rs', name: 'Serbien' },
	{ id: 'sc', name: 'Seychellen' },
	{ id: 'sl', name: 'Sierra Leone' },
	{ id: 'sg', name: 'Singapur' },
	{ id: 'sk', name: 'Slowakei' },
	{ id: 'si', name: 'Slowenien' },
	{ id: 'sb', name: 'Salomonen' },
	{ id: 'so', name: 'Somalia' },
	{ id: 'za', name: 'Südafrika' },
	{ id: 'es', name: 'Spanien' },
	{ id: 'lk', name: 'Sri Lanka' },
	{ id: 'sd', name: 'Sudan' },
	{ id: 'sr', name: 'Suriname' },
	{ id: 'sz', name: 'Eswatini' },
	{ id: 'se', name: 'Schweden' },
	{ id: 'ch', name: 'Schweiz' },
	{ id: 'sy', name: 'Syrien' },
	{ id: 'tw', name: 'Taiwan' },
	{ id: 'tj', name: 'Tadschikistan' },
	{ id: 'tz', name: 'Tansania' },
	{ id: 'th', name: 'Thailand' },
	{ id: 'tl', name: 'Timor-Leste' },
	{ id: 'tg', name: 'Togo' },
	{ id: 'to', name: 'Tonga' },
	{ id: 'tt', name: 'Trinidad und Tobago' },
	{ id: 'tn', name: 'Tunesien' },
	{ id: 'tr', name: 'Türkei' },
	{ id: 'tm', name: 'Turkmenistan' },
	{ id: 'tv', name: 'Tuvalu' },
	{ id: 'ug', name: 'Uganda' },
	{ id: 'ua', name: 'Ukraine' },
	{ id: 'ae', name: 'Vereinigte Arabische Emirate' },
	{ id: 'gb', name: 'Vereinigtes Königreich' },
	{ id: 'us', name: 'Vereinigte Staaten' },
	{ id: 'uy', name: 'Uruguay' },
	{ id: 'uz', name: 'Usbekistan' },
	{ id: 'vu', name: 'Vanuatu' },
	{ id: 'va', name: 'Vatikanstadt' },
	{ id: 've', name: 'Venezuela' },
	{ id: 'vn', name: 'Vietnam' },
	{ id: 'ye', name: 'Jemen' },
	{ id: 'zm', name: 'Sambia' },
	{ id: 'zw', name: 'Simbabwe' }
];

export const pznTypes = unsortedPznTypes.sort((a, b) =>
	a.name.localeCompare(b.name)
);
