import React from 'react';
import Typography from '@mui/material/Typography';

interface ItemTranslationTypographyProps {
	languageId?: string; // Make it optional if it may not always be present
	itemNameOld?: string; // Make it optional if it may not always be present
}

const ItemTranslationTypography: React.FC<ItemTranslationTypographyProps> = ({ languageId, itemNameOld }) => {
	const languageText = languageId ? `Alte Translation (${languageId})` : 'Alte Translation';
	const itemNameText = itemNameOld ? itemNameOld : 'Leer';

	const displayText = `${languageText}: ${itemNameText}`;

	return (
		<Typography variant="body1" sx={{marginTop: '1rem'}}>
			{displayText}
		</Typography>
	);
};

export default ItemTranslationTypography;