import * as React from 'react';
import { useState } from 'react';
import { useLogin, useNotify } from 'react-admin';
import {
	Box,
	Button,
	Container,
	Divider,
	TextField,
	Typography
} from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

const LoginPage = ({}) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const login = useLogin();
	const notify = useNotify();

	const handleSubmit = (e: any) => {
		e.preventDefault();
		login({ username: email, password }).catch(() =>
			notify('Deine E-Mail oder dein Passwort sind falsch')
		);
	};

	return (
		<Box
			id={'startBox'}
			sx={{
				position: 'relative',
				backgroundImage: 'url(/bg-home.jpg)',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				height: '100vh',
				maxHeight: '-webkit-fill-available' // Because of iOS Safari menu bar bug
			}}
		>
			<Container
				maxWidth={'md'}
				sx={{
					position: 'relative',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'column',
					justifyContent: { xs: 'space-around', md: 'center' },
					px: { xs: 0, sm: '16px' }
				}}
			>
				<Box
					component={'form'}
					onSubmit={handleSubmit}
					sx={{
						bgcolor: 'rgba(234,234,255,0.8)',
						width: '100%',
						borderRadius: { xs: 0, sm: '30px', md: '50px' },
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-evenly',
						flexDirection: 'column',
						p: 5
					}}
				>
					<Box
						component={'img'}
						sx={{
							width: { xs: '65%', sm: '50%' },
							paddingBottom: { xs: 1, sm: 3 }
						}}
						src={'/prosoom_logo.svg'}
						alt={'prosoom_logo'}
					/>
					<Typography
						variant={'h6'}
						fontWeight={'bold'}
						borderBottom={'1px solid'}
						alignSelf={'flex-start'}
						width={'100%'}
						mt={5}
						mb={2}
						sx={{ color: '#575D7C' }}
					>
						Beim Data-Management-Backend anmelden
					</Typography>
					<TextField
						autoComplete={'email'}
						name='email'
						label='E-Mail'
						variant='outlined'
						type={'email'}
						value={email}
						fullWidth
						inputProps={{ style: { backgroundColor: '#f6f9ff' } }}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<TextField
						autoComplete='password'
						name='password'
						label='Passwort'
						variant='outlined'
						type={'password'}
						value={password}
						fullWidth
						inputProps={{ style: { backgroundColor: '#f6f9ff' } }}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<Button
						variant='contained'
						startIcon={<PersonOutlineOutlinedIcon />}
						type={'submit'}
						sx={{ alignSelf: 'flex-end', mt: 2 }}
					>
						Anmelden
					</Button>
				</Box>
			</Container>
		</Box>
	);
};

export default LoginPage;
