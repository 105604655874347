import * as React from 'react';
import { Button, useNotify, useRefresh } from 'react-admin';
import BathtubIcon from '@mui/icons-material/Bathtub';
import axios from 'axios';

const CleanUrls = ({ data }: { data: any }) => {
	const refresh = useRefresh();
	const notify = useNotify();

	const cleanUrls = () => {
		axios
			.post(
				process.env.REACT_APP_HUB_API_URL + '/cleaner/cleanurls',
				data	, { withCredentials: true }
			)
			.then((response) => {
				console.log('response from API ', response.data);
				notify('Shop URL & Job Cleaning gestartet');
			})
			.catch((error) => {
				console.error(error);
			});

		setTimeout(() => {
			refresh();
		}, 3000);
	};

	return (
		<Button sx={{ color: '#783AA0' }} label='Clean' onClick={cleanUrls}>
			<BathtubIcon />
		</Button>
	);
};

export default CleanUrls;
