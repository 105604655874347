import React from 'react';
import { Checkbox, List, ListItem, ListItemText, styled } from '@mui/material';

interface Company {
	companyId: number;
	companyName: string;
}

interface CompanyListProps {
	companies: Company[];
	selectedCompanyId: number | null;
	onCheckboxChange: (id: number) => void;
}

const CompanyContainer = styled('div')({
	boxSizing: 'border-box',
});

const CompanyListItem = styled(ListItem)({
	display: 'flex',
	alignItems: 'center',
	borderRadius: '4px',
});

const CompanyList: React.FC<CompanyListProps> = ({ companies, selectedCompanyId, onCheckboxChange }) => {
	return (
		<CompanyContainer>
			<List disablePadding>
				{companies.map(company => (
					<CompanyListItem key={company.companyId} dense>
						<Checkbox
							edge="start"
							checked={company.companyId === selectedCompanyId}
							tabIndex={-1}
							disableRipple
							inputProps={{ 'aria-labelledby': `checkbox-list-label-${company.companyId}` }}
							onChange={() => onCheckboxChange(company.companyId)}
						/>
						<ListItemText id={`checkbox-list-label-${company.companyId}`} primary={company.companyName} />
					</CompanyListItem>
				))}
			</List>
		</CompanyContainer>
	);
};

export default CompanyList;
