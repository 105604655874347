import * as React from 'react';
import {
	Box,
	Typography,
	Button,
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ImageOrPlaceholder from '../../utils/ImageOrPlaceholder';
import axios from 'axios';
import { useEffect, useState } from 'react';

const ImageSuggested: React.FC = () => {
	const [item, setItem] = useState<{ itemId: number, itemName: string, imgUrl: string, manufacturer: string} | null>(null);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		fetchNextItem();
	}, []);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleTranslationProcess = (instruction: string) => {
		if (!item) return;
		if(instruction === 'remove')
		{
			axios.post(process.env.REACT_APP_HUB_API_URL + '/images/remove-suggestion', item, {withCredentials: true})
				.then(response => {
					fetchNextItem();
				})
				.catch(error => {
					console.error(`Error processing item with instruction "${instruction}":`, error);
				});
		}
		else
		{
			axios.post(process.env.REACT_APP_HUB_API_URL + '/images/take-suggestion', item, {withCredentials: true})
				.then(response => {
					fetchNextItem();
				})
				.catch(error => {
					console.error(`Error processing item with instruction "${instruction}":`, error);
				});
		}
	};

	const fetchNextItem = () => {
		axios.get(process.env.REACT_APP_HUB_API_URL + '/images/get-next-suggestion', {withCredentials: true})
			.then(response => {
				setItem(response.data);
			})
			.catch(error => {
				console.error('Error fetching the next item:', error);
			});
	};

	const handleMarkProcessed = () => handleTranslationProcess('take');
	const handleRemoveItem = () => handleTranslationProcess('remove');

	return (
		<Grid sx={{
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			gap: 2,
			padding: 2,
		}}>
			<Box sx={{
				width: '80%',
				justifyContent: 'center',
			}}>
				<Typography variant="h4" sx={{ textAlign: 'center' }}>{item?.itemName ? item.itemName : 'Leer'}</Typography>

				<Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
					<ImageOrPlaceholder src={item ? item.imgUrl : ''} height={500} alt="Img" />
				</Box>

				<Typography variant="body1" sx={{ textAlign: 'center' }}>Hersteller: {item?.manufacturer ? item.manufacturer : 'Leer'}</Typography>
			</Box>

			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>{"Image Suggested Erklärung"}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						In diesem Frontend werden Bilder angezeigt, die durch die Prosoom-AI vorgeschlagen wurden.
						Passt das Bild nicht zu dem Namen, dann drückt man den Button "BILD LÖSCHEN". Ist
						das Bild passend, drückt man den Button "BILD ÜBERNEHMEN".
						Viel Spaß :)
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary" autoFocus>
						Schließen
					</Button>
				</DialogActions>
			</Dialog>

			<Grid container sx={{
				width: '80%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
				<Button variant="contained" startIcon={<InfoIcon />} sx={{
					padding: '8px 16px',
					margin: '4px',
					marginRight: 'auto',
				}} onClick={handleClickOpen}>Hilfe</Button>
				<Box sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flex: '1 30px',
				}}>
					<Button variant="contained" startIcon={<DeleteIcon />} sx={{
						padding: '8px 16px',
						margin: '4px',
					}} onClick={handleRemoveItem}>Bild löschen</Button>

					<Button variant="contained" endIcon={<CheckCircleIcon />} sx={{
						padding: '8px 16px',
						margin: '4px',
					}} onClick={handleMarkProcessed}>Bild übernehmen</Button>
				</Box>
			</Grid>
		</Grid>
	);
};

export default ImageSuggested;