import * as React from 'react';
import { Button, useNotify, useRefresh } from 'react-admin';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import axios from 'axios';
import { useState } from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button as Btn,
	CircularProgress
} from '@mui/material';
import ReactJson from 'react-json-view';

const TestShops = ({ data }: { data: any }) => {
	const refresh = useRefresh();
	const notify = useNotify();
	const [show, setShow] = useState(false);

	const [response, setResponse] = useState({});
	const [openDialog, setOpenDialog] = useState(false);

	const testShops = () => {
		setShow(true);

		axios
			.post(process.env.REACT_APP_HUB_API_URL + '/shops/test-single', {
				shopId: data.id
			},{ withCredentials: true })
			.then((response) => {
				if (response.data) {
					setResponse(response.data);
					notify(
						'Shop wurde getestet! Der Response erscheint unter Response'
					);
					setOpenDialog(true);
				} else {
					notify(
						'Es gab einen Fehler, bitte die Selectoren und Product URL überprüfen!',
						{ type: 'warning' }
					);
				}
			})
			.catch((error) => {
				if (error.response.data.message) {
					notify(
						error.response.data.code +
							' ' +
							error.response.data.message,
						{ type: 'warning' }
					);
				} else {
					notify(error.message);
				}
			})
			.finally(() => setShow(false));
	};

	return (
		<>
			<Button
				sx={{ color: '#74CB5B' }}
				label='Test selectors'
				onClick={testShops}
				disabled={show}
			>
				<PlaylistAddCheckIcon />
			</Button>
			<Dialog fullWidth={true} maxWidth={'xl'} open={openDialog}>
				<DialogTitle>JSON Test Response Crawler</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						<ReactJson
							collapsed={1}
							src={response}
							collapseStringsAfterLength={150}
							name={'response'}
						/>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Btn
						variant={'contained'}
						autoFocus
						onClick={() => setOpenDialog(false)}
					>
						Schließen
					</Btn>
				</DialogActions>
			</Dialog>
			{show && (
				<CircularProgress style={{ color: '#74CB5B' }} size={25} />
			)}
		</>
	);
};

export default TestShops;
