import React from 'react';
import { Show, SimpleShowLayout, TextField, DateField, ArrayField, Datagrid, FunctionField, useRecordContext } from 'react-admin';
import SpeedtestGraph from "./speedtest/SpeedtestGraph";

const statusRenderer = (record: any) => {
    return record.serviceStatus === 1 ? 'Aktiv' : 'Inaktiv';
};

const SpeedtestArrayField = () => {
    const record = useRecordContext();
    if (!record) return null;

    return <SpeedtestGraph data={record.speedtest} />;
};

const ClientShow = (props: any) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" label="Name" />
            <TextField source="location" label="Location" />
            <TextField source="responsible" label="Responsible" />
            <TextField source="router" label="Router" />
            <TextField source="routerSpec" label="Router Spec" emptyText="<null>" />

            <TextField source="ip" label="IP" />
            <TextField source="ipRmq" label="IP RMQ" />
            <TextField source="ipRemoteIt" label="IP Remote IT" />

            <DateField source="updatedAt" label="Last Updated" showTime />

            <ArrayField source="services">
                <Datagrid bulkActionButtons={false}>
                    <TextField source="serviceName" label="Service Name" />
                    <TextField source="serviceVersion" label="Service Version" />
                    <FunctionField label="Service Status" render={statusRenderer} />
                    <DateField source="updatedAt" label="Updated At" showTime />
                </Datagrid>
            </ArrayField>

            <SpeedtestArrayField />
        </SimpleShowLayout>
    </Show>
);

export default ClientShow;
