import * as React from 'react';
import {
	Box,
	Typography,
	Button,
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions, TextField,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import { useEffect, useState } from 'react';
import ErrorDialog from '../utils/ErrorDialog';
import SynonymList from './SynonymList';
import CompanyList from './CompanyList';
import HorizontalCheckboxGroup from './HorizontalCheckboxGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

class Company {
	companyId: number;
	companyName: string;
	companyRootUrl: string;
	street: string;
	postalzip: string;
	city: string;
	state: string;
	countryId: string;

	constructor(
		company_id: number,
		company_name: string,
		company_root_url: string,
		street: string,
		postalzip: string,
		city: string,
		state: string,
		country_id: string
	) {
		this.companyId = company_id;
		this.companyName = company_name;
		this.companyRootUrl = company_root_url;
		this.street = street;
		this.postalzip = postalzip;
		this.city = city;
		this.state = state;
		this.countryId = country_id;
	}
}

class Synonym {
	id: number;
	synonym: string;
	valid: boolean;

	constructor(
		id: number,
		synonym: string,
		valid: boolean,
	) {
		this.id = id;
		this.synonym = synonym;
		this.valid = valid;
	}
}

const CompanySuggested: React.FC = () => {
	const [company, setCompany] =
		useState<Company | null>(null);
	const [fittingCompanies, setFittingCompanies] = useState<Company[]>([]);
	const [synonyms, setSynonyms] = useState<Synonym[]>([]);
	const [selectedManufacturer, setSelectedManufacturer] = useState<string>('newManufacturer');
	const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(null);
	const [companyName, setCompanyName] = useState<string>('');
	const [companyRootUrl, setCompanyRootUrl] = useState<string>('');
	const [street, setStreet] = useState<string>('');
	const [postalzip, setPostalZip] = useState<string>('');
	const [city, setCity] = useState<string>('');
	const [state, setState] = useState<string>('');
	const [countryId, setCountryId] = useState<string>('');
	const [companyId, setCompanyId] = useState<string>('');
	const [open, setOpen] = useState(false);
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [dialogHeader, setDialogHeader] = useState('');

	useEffect(() => {
		getNextCompany();
	}, []);

	const handleCheckboxChangeSynonyms = (id: number) => {
		if(synonyms)
		{
			setSynonyms(prevSynonyms =>
				prevSynonyms.map(synonym =>
					synonym.id === id ? { ...synonym, valid: !synonym.valid } : synonym
				)
			);
		}
	};

	const handleCompanyCheckboxChange = (id: number) => {
		setSelectedCompanyId(prevId => (prevId === id ? null : id));
	};

	const handleManufacturerChange = (value: string) => {
		setSelectedManufacturer(value);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleErrorDialogClose = () => {
		setErrorDialogOpen(false);
	};

	function getNextCompany() {
		axios.get(process.env.REACT_APP_HUB_API_URL + '/company/get-next-company',
			{ withCredentials: true })
			.then(response => {
				setCompany(response.data.company);
				setSynonyms(response.data.synonyms);
				setFittingCompanies(response.data.fittingCompanies);
				setCompanyName(response.data?.company.companyName ? response.data.company.companyName : '');
				setCompanyRootUrl(response.data?.company.companyRootUrl ? response.data.company.companyRootUrl : '');
				setStreet(response.data?.company.street ? response.data.company.street : '');
				setPostalZip(response.data?.company.postalzip ? response.data.company.postalzip : '');
				setCity(response.data?.company.city ? response.data.company.city : '');
				setState(response.data?.company.state ? response.data.company.state : '');
				setCountryId(response.data?.company.countryId ? response.data.company.countryId : '');
				setSelectedManufacturer('newManufacturer');
				setCompanyId('');
			})
			.catch(error => {
				setErrorMessage('Fehler beim holen des nächsten Herstellers');
				setDialogHeader('Fehler');
				setErrorDialogOpen(true);
			});
	}

	const handleSave = () => {
		if(selectedManufacturer === 'newManufacturer')
		{
			const newCompany = new Company(
				company?.companyId ? company.companyId : -1,
				companyName,
				companyRootUrl,
				street,
				postalzip,
				city,
				state,
				countryId,
			)
			const result = {
				company: newCompany,
				synonyms: synonyms,
			}

			axios.post(process.env.REACT_APP_HUB_API_URL + '/company/save-company-new', result, {withCredentials: true})
				.then(response => {
					getNextCompany();
				})
				.catch(error => {
					setErrorMessage('Fehler beim item id lookup: '+ error);
					setDialogHeader('Fehler');
					setErrorDialogOpen(true);
				});
		}
		else if(selectedManufacturer === 'existingManufacturer')//TODO fixen, hier wird als finalId 0 übertragen
		{
			const manualCompanyId = companyId.length > 0 ? Number(companyId) : NaN;
			if(!isNaN(manualCompanyId) || selectedCompanyId)
			{
				const finalCompanyId = !isNaN(manualCompanyId) ? manualCompanyId : selectedCompanyId;
				const newCompany = new Company(
					company?.companyId ? company.companyId : -1,
					companyName,
					companyRootUrl,
					street,
					postalzip,
					city,
					state,
					countryId,
				)
				const result = {
					company: newCompany,
					synonyms: synonyms,
					existingCompanyId: finalCompanyId,
				}

				axios.post(process.env.REACT_APP_HUB_API_URL + '/company/save-company-existing', result, {withCredentials: true})
					.then(response => {
						getNextCompany();
					})
					.catch(error => {
						setErrorMessage('Fehler beim item id lookup: '+ error);
						setDialogHeader('Fehler');
						setErrorDialogOpen(true);
					});
			}
			else
			{
				setErrorMessage('Bitte eine company id eingeben oder eine company auswählen.');
				setDialogHeader('Fehler');
				setErrorDialogOpen(true);
			}
		}
		else {
			setErrorMessage('Bitte eine Auswahl darüber treffen, ob es sich um einen bestehenden' +
				' Hersteller handelt oder dieser neu im System angelegt werden muss.');
			setDialogHeader('Fehler');
			setErrorDialogOpen(true);
		}
	};

	const handleDelete = () => {
		const result = {
			companyId: company?.companyId ? company.companyId : -1,
		}

		axios.post(process.env.REACT_APP_HUB_API_URL + '/company/delete-company', result, {withCredentials: true})
			.then(response => {
				getNextCompany();
			})
			.catch(error => {
				setErrorMessage('Fehler beim item id lookup: '+ error);
				setDialogHeader('Fehler');
				setErrorDialogOpen(true);
			});
	};

	return (
		<Grid
			container
			direction="column"
			alignItems="center"
		>
			<Grid item sx={{ width: '80%', textAlign: 'center', marginTop: 2 }}>
				<Typography variant="h4">
					{company?.companyName ? company?.companyName : 'Leer'}
				</Typography>
			</Grid>

			<Box sx={{ height: '2em' }} />

			<Grid item container spacing={2} justifyContent="center" sx={{ width: '80%'}}>
				<Grid item xs={12} md={6} container direction="column" alignItems="center">
					<Typography variant="h5">
						{'Hersteller Daten'}
					</Typography>
					<Grid item>
						<TextField
							label={'Company Name'}
							value={companyName}
							onChange={(e) => setCompanyName(e.target.value)}
							fullWidth
							sx={{ width: '30em', marginBottom: 1 }}
						/>
					</Grid>
					<Grid item>
						<TextField
							label={'Company Url'}
							value={companyRootUrl}
							onChange={(e) => setCompanyRootUrl(e.target.value)}
							fullWidth
							sx={{ width: '30em', marginBottom: 1 }}
						/>
					</Grid>
					<Grid item>
						<TextField
							label={'Street'}
							value={street}
							onChange={(e) => setStreet(e.target.value)}
							fullWidth
							sx={{ width: '30em', marginBottom: 1 }}
						/>
					</Grid>
					<Grid item>
						<TextField
							label={'PLZ'}
							value={postalzip}
							onChange={(e) => setPostalZip(e.target.value)}
							fullWidth
							sx={{ width: '30em', marginBottom: 1 }}
						/>
					</Grid>
					<Grid item>
						<TextField
							label={'City'}
							value={city}
							onChange={(e) => setCity(e.target.value)}
							fullWidth
							sx={{ width: '30em', marginBottom: 1 }}
						/>
					</Grid>
					<Grid item>
						<TextField
							label={'State'}
							value={state}
							onChange={(e) => setState(e.target.value)}
							fullWidth
							sx={{ width: '30em', marginBottom: 1 }}
						/>
					</Grid>
					<Grid item>
						<TextField
							label={'Country ID'}
							value={countryId}
							onChange={(e) => setCountryId(e.target.value)}
							fullWidth
							sx={{ width: '30em', marginBottom: 1 }}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12} md={6} container direction="column" alignItems="center">
					<Typography variant="h5">
						{'Synonyme'}
					</Typography>
					<SynonymList synonyms={synonyms} onCheckboxChange={handleCheckboxChangeSynonyms} />
				</Grid>
			</Grid>

			<Box sx={{ height: '2em' }} />

			<Grid item container direction="column" alignItems="center" justifyContent="center" spacing={2} sx={{ width: '80%' }}>
				<Typography variant="h5">
					{'Ähnliche Hersteller'}
				</Typography>
				<Grid item container spacing={1} justifyContent="center">
					{fittingCompanies.map(company => (
						<Grid item xs={12} sm={6} md={4} key={company.companyId}>
							<CompanyList
								companies={[company]}
								selectedCompanyId={selectedCompanyId}
								onCheckboxChange={handleCompanyCheckboxChange}
							/>
						</Grid>
					))}
				</Grid>
			</Grid>

			<Grid item container alignItems="center" justifyContent="center" spacing={2}>
				<Grid item>
					<Typography variant="body1">Manuelle Company Id: </Typography>
				</Grid>
				<Grid item>
					<TextField
						label={'CompanyId'}
						value={companyId}
						onChange={(e) => setCompanyId(e.target.value)}
					/>
				</Grid>
			</Grid>

			<Grid item>
				<HorizontalCheckboxGroup
					selectedValue={selectedManufacturer}
					onChange={handleManufacturerChange}
				/>
			</Grid>

			<Grid item container justifyContent="center" spacing={2} sx={{ width: '80%' }}>
				<Grid item>
					<Button
						variant="contained"
						startIcon={<InfoIcon />}
						sx={{ padding: '8px 16px' }}
						onClick={handleClickOpen}
					>
						Hilfe
					</Button>
				</Grid>
				<Grid item>
					<Button
						variant="contained"
						startIcon={<DeleteIcon />}
						sx={{ padding: '8px 16px' }}
						onClick={handleDelete}
					>
						Löschen
					</Button>
				</Grid>
				<Grid item>
					<Button
						variant="contained"
						endIcon={<CheckCircleIcon />}
						sx={{ padding: '8px 16px' }}
						onClick={handleSave}
					>
						Speichern
					</Button>
				</Grid>
			</Grid>

			<Box sx={{ height: '2em' }} />

			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>{"Suggested Company Erklärung"}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						In diesem Frontend können neue Companies / Hersteller angelegt werden.
						Auf der linken Seite werden die Daten des Herstellers geladen, diese können bei
						Fehlern angepasst werden. Auf der rechten Seite sieht man die gefundenen Synonyme mit
						jeweils einer Checkbox. Passt ein Synonym nicht, kann es durch die Checkbox abgewählt werden.
						Gibt es den Hersteller bereits im System kann dieser entweder unter "Ähnliche Hersteller" angezeigt
						werden, dann wählt man diesen wieder mit der entsprechenden Checkbox aus. Falls es den Hersteller
						schon gibt, aber er dort nicht angezeigt wird, kann man über Manuelle Company Id die entsprechende
						ID setzen. Abschließend wählt man zur Sicherheit nochmal Neuer Hersteller oder bestehender Hersteller
						aus als letzten Check. Anschließend drückt man auf "Speichern" und der nächste Kandidat wird geladen.
						Ist es kein Hersteller, kann man ihn über "Löschen" entfernen.
						Viel Spaß :)
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary" autoFocus>
						Schließen
					</Button>
				</DialogActions>
			</Dialog>

			<ErrorDialog
				open={errorDialogOpen}
				onClose={handleErrorDialogClose}
				errorMessage={errorMessage}
				header={dialogHeader}
			/>
		</Grid>
	);
};

export default CompanySuggested;