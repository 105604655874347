import * as React from 'react';
import PropTypes from 'prop-types';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { useRecordContext } from 'react-admin';

const LabelMatchName = ({
	label,
	source
}: {
	label: string;
	source: string;
}) => {
	const record = useRecordContext();
	return (
		<p
			style={{
				display: 'flex',
				alignItems: 'center',
				color: record[source] === true ? 'inherit' : 'red'
			}}
		>
			{/*<ReportProblemOutlinedIcon sx={{paddingRight: "5px", display: record[source] === true ? 'none' : 'inline-block'}} color="error"/>*/}
			<strong>{label}</strong>
		</p>
	);
};

LabelMatchName.propTypes = {
	label: PropTypes.string,
	record: PropTypes.object,
	source: PropTypes.string.isRequired
};

export default LabelMatchName;
