import * as React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

export const CircularProgressWithLabel = ({
	record,
	source
}: {
	record: any;
	source: any;
}) => {
	function circularColor() {
		if (record[source] <= 25) {
			return '#EF4338';
		}
		if (record[source] < 100) {
			return '#ffeb00';
		}
		if (record[source] === 100) {
			return '#7BC656';
		}
	}
	if (record) {
		return (
			<Box position='relative' display='inline-flex'>
				<CircularProgress
					variant='determinate'
					value={record[source]}
					style={{ color: circularColor() }}
				/>
				<Box
					top={0}
					left={0}
					bottom={0}
					right={0}
					position='absolute'
					display='flex'
					alignItems='center'
					justifyContent='center'
				>
					<Typography variant='caption'>{`${Math.round(
						record[source]
					)}%`}</Typography>
				</Box>
			</Box>
		);
	} else {
		return (
			<CircularProgress
				style={{
					position: 'absolute',
					left: '0px',
					color: 'black',
					marginLeft: '100px'
				}}
				size={30}
			/>
		);
	}
};
