import * as React from 'react';
import {
	Box,
	Typography,
	Button,
	Checkbox,
	FormControlLabel,
	TextField,
	Grid,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Dialog,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ImageOrPlaceholder from '../../utils/ImageOrPlaceholder';
import axios from 'axios';
import { useEffect, useState } from 'react';

const ItemGenerated: React.FC = () => {
	const [item, setItem] =
		useState<{ itemId: number, itemNumberManufacturer: string, pznDe: string, pznAt: string, gtin8: string, gtin12: string, gtin13: string, gtin14: string, asin: string, isbn: string, companyId: number, manufacturer: string, countryCode: string, articleNumber: string, nonStandardNumber: string, imgUrl: string, itemName: string, fcc: string} | null>(null);
	const [name, setName] = useState<string>('');
	const [companyId, setCompanyId] = useState<number>(-1);
	const [itemNumberManufacturer, setItemNumberManufacturer] = useState<string>('');
	const [pznDe, setPznDe] = useState<string>('');
	const [pznAt, setPznAt] = useState<string>('');
	const [gtin8, setGtin8] = useState<string>('');
	const [gtin12, setGtin12] = useState<string>('');
	const [gtin13, setGtin13] = useState<string>('');
	const [gtin14, setGtin14] = useState<string>('');
	const [isbn, setIsbn] = useState<string>('');
	const [asin, setAsin] = useState<string>('');
	const [imgUrl, setImgUrl] = useState<string>('');
	const [countryCode, setCountryCode] = useState<string>('');
	const [takeImgUrl, setTakeImgUrl] = useState<boolean>(false);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		fetchNextItem()
	}, []);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleItemProcess = (instruction: string) => {
		if (!item) return;

		const result = {
			itemId: item.itemId,
			companyId: companyId && companyId !== -1 ? companyId : null,
			nonStandardNumber: itemNumberManufacturer && itemNumberManufacturer !== '' ? itemNumberManufacturer : null,
			pznDe: pznDe && pznDe !== '' ? pznDe : null,
			pznAt: pznAt && pznAt !== '' ? pznAt : null,
			gtin8: gtin8 && gtin8 !== '' ? gtin8 : null,
			gtin12: gtin12 && gtin12 !== '' ? gtin12 : null,
			gtin13: gtin13 && gtin13 !== '' ? gtin13 : null,
			gtin14: gtin14 && gtin14 !== '' ? gtin14 : null,
			isbn: isbn && isbn !== '' ? isbn : null,
			asin: asin && asin !== '' ? asin : null,
			fcc: item.fcc,
			itemName: name,
			countryCode: countryCode,
			articleNumber: item.articleNumber,
			imgUrl: imgUrl,
			takeImgUrl: takeImgUrl,
			instruction,
		};

		axios.post(process.env.REACT_APP_HUB_API_URL + '/item/process-generated', result, {withCredentials: true})
			.then(response => {
				fetchNextItem();
			})
			.catch(error => {
				console.error(`Error processing item with instruction "${instruction}":`, error);
			});
	};

	const fetchNextItem = () => {
		axios.get(process.env.REACT_APP_HUB_API_URL + '/item/get-next-item', {withCredentials: true})
			.then(response => {
				setItem(response.data);
				setName(response.data?.itemName ? response.data.itemName : '');
				setCompanyId(response.data?.companyId ? response.data.companyId : -1);
				setItemNumberManufacturer(response.data?.itemNumberManufacturer ? response.data.itemNumberManufacturer : '');
				setPznDe(response.data?.pznDe ? response.data.pznDe : '');
				setPznAt(response.data?.pznAt ? response.data.pznAt : '');
				setGtin8(response.data?.gtin8 ? response.data.gtin8 : '');
				setGtin12(response.data?.gtin12 ? response.data.gtin12 : '');
				setGtin13(response.data?.gtin13 ? response.data.gtin13 : '');
				setGtin14(response.data?.gtin14 ? response.data.gtin14 : '');
				setIsbn(response.data?.isbn ? response.data.isbn : '');
				setAsin(response.data?.asin ? response.data.asin : '');
				setImgUrl(response.data?.imgUrl ? response.data.imgUrl : '');
				setCountryCode(response.data?.countryCode ? response.data.countryCode : '');
				setTakeImgUrl(false);
			})
			.catch(error => {
				console.error('Error fetching the next item:', error);
			});
	};

	const handleMarkProcessed = () => handleItemProcess('take');
	const handleRemoveItem = () => handleItemProcess('remove');

	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			gap: 2,
			padding: 2,
			height: '100vh',
		}}>
			<Typography variant="h4" sx={{ textAlign: 'center' }}>Neues Item</Typography>

			<Grid container spacing={1} direction={'row'}>
				<Grid item xs={6}>
					<Grid container direction="column">
						<Grid item>
							<TextField
								label={item?.countryCode ? `Name (${item.countryCode})` : 'Name'}
								value={name}
								onChange={(e) => setName(e.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid item>
							<TextField
								label={'Country Code'}
								value={countryCode}
								onChange={(e) => setCountryCode(e.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid item>
							<TextField
								label={item?.manufacturer ? `Hersteller ID für: (${item.manufacturer})` : 'Hersteller ID'}
								value={companyId}
								onChange={(e) => setCompanyId(Number(e.target.value))}
								fullWidth
							/>
						</Grid>
						<Grid item>
							<TextField
								label={'Item Number Manufacturer'}
								value={itemNumberManufacturer}
								onChange={(e) => setItemNumberManufacturer(e.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid item>
							<TextField
								label={'PZN DE'}
								value={pznDe}
								onChange={(e) => setPznDe(e.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid item>
							<TextField
								label={'PZN AT'}
								value={pznAt}
								onChange={(e) => setPznAt(e.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid item>
							<TextField
								label={'GTIN 8'}
								value={gtin8}
								onChange={(e) => setGtin8(e.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid item>
							<TextField
								label={'GTIN 12'}
								value={gtin12}
								onChange={(e) => setGtin12(e.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid item>
							<TextField
								label={'GTIN 13'}
								value={gtin13}
								onChange={(e) => setGtin13(e.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid item>
							<TextField
								label={'GTIN 14'}
								value={gtin14}
								onChange={(e) => setGtin14(e.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid item>
							<TextField
								label={'ASIN'}
								value={asin}
								onChange={(e) => setAsin(e.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid item>
							<TextField
								label={'ISBN'}
								value={isbn}
								onChange={(e) => setIsbn(e.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid item>
							<TextField
								label={'Bild URL'}
								value={imgUrl}
								onChange={(e) => setImgUrl(e.target.value)}
								fullWidth
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={6} container direction="column">
					<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
						<ImageOrPlaceholder src={item?.imgUrl ? item.imgUrl : ''} alt="Img" />
					</Box>
					<Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
						<FormControlLabel label="Bild URL Übernehmen" control={<Checkbox checked={takeImgUrl} onChange={(e) => setTakeImgUrl(e.target.checked)} />} />
					</Box>
				</Grid>
			</Grid>

			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>{"Item Generated Erklärung"}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						In diesem Frontend werden neue potentielle Items angezeigt.
						Auf der linken Seite werden alle Item Informationen angezeigt. Hier gilt zu beachten, dass im
						Feld PZN DE und GTIN 8 das jeweils andere stehen kann, hier sollte manuell nochmal nachgeprüft
						werden um welche Art von Nummer es sich handelt mithilfe von Google. Ebenfalls kann es möglich
						sein, dass im PZN DE Feld eine PZN AT steht (führende Null, da PZN AT nur 7 Stellen haben kann),
						hier bitte ebenfalls prüfen, da sich teilweise PZN DE und PZN AT überschneiden.
						Auf der rechten Seite wird ggf. das Produktbild angezeigt.
						Passen die Informationen nicht zum Item kann diese mit "ITEM LÖSCHEN" gelöscht werden. Sind alle
						Informationen geprüft und das Item passen, kann man dieses mit "ITEM ÜBERNEHMEN" in unser System
						aufnehmen. Dabei gilt zu beachten, die Checkbox "Bild URL Übernehmen" zu setzen, falls das Bild
						zum Produkt passt. Das Bild läd manchmal etwas langsamer, also solange noch das alte Bild oder
						noch kein Bild zu sehen ist am besten kurz warten. Falls "No Image" angezeigt wird haben wir aktuell
						noch kein Bild gefunden.
						Viel Spaß :)
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary" autoFocus>
						Schließen
					</Button>
				</DialogActions>
			</Dialog>

			<Grid container sx={{
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
				<Button variant="contained" startIcon={<InfoIcon />} sx={{
					padding: '8px 16px',
					margin: '4px',
					marginRight: 'auto',
				}} onClick={handleClickOpen}>Hilfe</Button>
				<Box sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flex: '1 0 auto',
				}}>
					<Button variant="contained" startIcon={<DeleteIcon />} sx={{
						padding: '8px 16px',
						margin: '4px',
					}} onClick={handleRemoveItem}>Item Löschen</Button>

					<Button variant="contained" endIcon={<CheckCircleIcon />} sx={{
						padding: '8px 16px',
						margin: '4px',
					}} onClick={handleMarkProcessed}>Item übernehmen</Button>
				</Box>
			</Grid>
		</Box>
	);
};

export default ItemGenerated;