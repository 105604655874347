import React, { useEffect, useState } from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, AutocompleteInput, BooleanInput, RaRecord } from "react-admin";
import axios from 'axios';

const ShopLogo = ({ shopId }: { shopId: string | number }) => {
    const [logoUrl, setLogoUrl] = useState('/img_placeholder.jpg');

    useEffect(() => {
        if (shopId) {
            const logoUrlFromService = `https://prosoom-shop-logos.s3.eu-central-1.amazonaws.com/${shopId}.webp`;
            axios
                .get(logoUrlFromService)
                .then(() => setLogoUrl(logoUrlFromService))
                .catch(() => setLogoUrl('/img_placeholder.jpg'));
        }
    }, [shopId]);

    return (
        <img
            src={logoUrl}
            alt="Shop Logo"
            style={{ width: 100, height: 100, objectFit: 'contain', marginBottom: '1rem' }}
        />
    );
};

export const ShopsMarketplaceCreate = () => {
    const [currentShopId, setCurrentShopId] = useState<string | number>('');

    const handleShopChange = (shop: "" | RaRecord<import("ra-core").Identifier>) => {
        if (shop && typeof shop !== "string") {
            setCurrentShopId(shop.id);
        } else {
            setCurrentShopId('');
        }
    };

    return (
        <Create title="Neuen Shop Marketplace anlegen">
            <SimpleForm>
                <ShopLogo shopId={currentShopId} />
                <TextInput source="param" label="Parameter" fullWidth helperText={false} />
                <ReferenceInput source="shopId" reference="shops" label="Shop Name">
                    <AutocompleteInput
                        optionText={(record) => `${record.shopUrlHostname} (${record.id})`}
                        filterToQuery={(searchText) => ({ shopUrlHostname: searchText })}
                        fullWidth
                        helperText={false}
                        onChange={(e, newValue) => handleShopChange(newValue)}
                    />
                </ReferenceInput>
                <ReferenceInput source="marketplace_id" reference="marketplaces" label="Marketplace">
                    <AutocompleteInput
                        optionText={(record) => `${record.id} (${record.name})`}
                        filterToQuery={(searchText) => ({ marketplaceName: searchText })}
                        fullWidth
                        helperText={false}
                    />
                </ReferenceInput>
                <BooleanInput source="url_already_include_parameter" label="URL Already Includes Parameter?" />
            </SimpleForm>
        </Create>
    );
};
