import * as React from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@mui/material';

export const DataTable = (props: any) => {
	return (
		<TableContainer sx={{ pt: '25px' }} component='div'>
			<Table size='small' aria-label='a dense table'>
				<TableHead>
					<TableRow>
						<TableCell sx={{ fontWeight: 'bold' }}>Label</TableCell>
						<TableCell sx={{ fontWeight: 'bold' }} align='right'>
							Value
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.data.map((row: any) => (
						<TableRow key={row.key}>
							<TableCell component='th' scope='row'>
								{row.label}
							</TableCell>
							<TableCell align='right'>{row.value}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
