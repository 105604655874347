import { Menu } from 'react-admin';
import RttIcon from '@mui/icons-material/Rtt';
import EditNoteIcon from '@mui/icons-material/EditNote';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import FactoryIcon from '@mui/icons-material/Factory';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';

export const MyMenu = () => (
	<Menu>
		<Menu.DashboardItem />
		<Menu.ResourceItems />
		<Menu.Item to="/item-translation" primaryText="Item Translation" leftIcon={<RttIcon />}/>
		<Menu.Item to="/item-generation" primaryText="Item Generation" leftIcon={<EditNoteIcon />}/>
		<Menu.Item to="/image-problem" primaryText="Image Problem" leftIcon={<BrokenImageIcon />}/>
		<Menu.Item to="/image-suggestion" primaryText="Image Suggested" leftIcon={<ImageSearchIcon />}/>
		<Menu.Item to="/image-candidate" primaryText="Image Candidate" leftIcon={<PhotoLibraryIcon />}/>
		<Menu.Item to="/image-upload" primaryText="Image Upload" leftIcon={<AddPhotoAlternateIcon />}/>
		<Menu.Item to="/company-suggested" primaryText="Suggested Company" leftIcon={<FactoryIcon />}/>
	</Menu>
);