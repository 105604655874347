// ErrorDialog.tsx
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface ErrorDialogProps {
	open: boolean;
	onClose: () => void;
	errorMessage: string;
	header: string;
}

const ErrorDialog: React.FC<ErrorDialogProps> = ({ open, onClose, errorMessage, header }) => {
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{header}</DialogTitle>
			<DialogContent>
				<Typography>{errorMessage}</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Schließen
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ErrorDialog;
