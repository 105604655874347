import * as React from 'react';
import HttpIcon from '@mui/icons-material/Http';
import { DataTable } from './DataTable';
import { Card, CardContent, Grid, Typography } from '@mui/material';

function UrlsCard(props: any) {
	return (
		<Grid item xs={12} sx={{ position: 'relative', marginTop: '25px' }}>
			<HttpIcon
				className={'iconDashboard'}
				style={{ background: '#349187' }}
			/>
			<Card sx={{ paddingBottom: '10px' }}>
				<CardContent>
					<Typography
						color={'textSecondary'}
						variant='h4'
						align='center'
					>
						Urls
					</Typography>
					<DataTable data={props.data} />
				</CardContent>
			</Card>
		</Grid>
	);
}

export default UrlsCard;
