import * as React from 'react';
import {
	TextInput,
	BooleanInput,
	RadioButtonGroupInput,
	required,
	SelectInput,
	NumberInput
} from 'react-admin';
import { pznTypes } from '../pznTypes';
import { Box, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';

export const RequiredStep = (props: any) => {
	const { watch, setValue } = useFormContext();
	useEffect(() => {
		try {
			const url = new URL(watch('productUrl'));
			const hostname = url.host;
			if (hostname) {
				setValue('shopUrl', url.protocol + '//' + hostname);
			}
		} catch (e: any) {
			console.log(e.message);
		}
	}, [watch('productUrl')]);

	return (
		<>
			<Typography
				variant='h6'
				gutterBottom
				sx={{ borderBottom: '1px solid' }}
			>
				Erforderliche Felder
			</Typography>
			<BooleanInput
				source='status'
				label={'Status (Inaktiv/Aktiv)'}
				defaultValue={false}
			/>
			<BooleanInput
				source='topShop'
				label={'Top Shop'}
				defaultValue={false}
			/>
			<RadioButtonGroupInput
				sx={{ width: '100%' }}
				source='priority'
				label='Priorität'
				defaultValue={7}
				choices={[
					{ id: '1', name: 1 },
					{ id: '2', name: 2 },
					{ id: '3', name: 3 },
					{ id: '4', name: 4 },
					{ id: '5', name: 5 },
					{ id: '6', name: 6 },
					{ id: '7', name: 7 },
					{ id: '8', name: 8 },
					{ id: '9', name: 9 },
					{ id: '10', name: 10 }
				]}
				translateChoice={false}
			/>
			<SelectInput
				source='pznType'
				label='PZN Type'
				validate={[required()]}
				choices={pznTypes}
				defaultValue='de'
				sx={{ width: '100%' }}
			/>
			<NumberInput
				validate={[required()]}
				min={1}
				step={1}
				source='nextRetryIn'
				label='Crawling wiederholung (In Tagen)'
				defaultValue={7}
				sx={{ width: '100%' }}
			/>
			<TextInput
				validate={[required()]}
				sx={{ width: '100%' }}
				label='Produkt URL'
				source='productUrl'
				type='url'
			/>
			<TextInput
				validate={[required()]}
				sx={{ width: '100%' }}
				label='Shop URL'
				source='shopUrl'
				type='url'
			/>
			<Typography
				variant='h6'
				gutterBottom
				sx={{
					borderBottom: '1px solid',
					width: '100%',
					marginBottom: '25px'
				}}
			>
				Optionale Felder
			</Typography>
			<TextInput
				sx={{ width: '100%' }}
				label='Shop Logo URL'
				source='shopLogoUrl'
				type='url'
			/>
			<TextInput
				sx={{ width: '100%' }}
				label='Deny Parameter'
				source='denyParam'
				helperText='Bei mehreren Werten bitte so eingeben: xoid, random, key (* für ALLE Parameter)'
			/>
			<TextInput
				sx={{ width: '100%' }}
				label='Deny Path'
				source='denyPath'
				helperText='Bei mehreren Werten bitte so eingeben: xoid, random, key'
			/>
			<BooleanInput source='removeHash' label='Remove # from URL' />
			<TextInput label='Affiliate Prefix URL' source='affiliatePrefix' />
			<TextInput
				rows='5'
				fullWidth
				label='Notes'
				multiline
				source='note'
			/>
		</>
	);
};
