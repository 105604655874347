import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Edit,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    BooleanInput,
    useRecordContext,
    SimpleForm,
    useInput
} from "react-admin";

const PageTitle = () => {
    const record = useRecordContext();
    return <>Bearbeiten von "{record?.shop?.shopUrlHostname}"</>;
};

const ShopNameInput = ({ onShopChange }: { onShopChange: (shopId: string | number) => void }) => {
    const { field } = useInput({ source: 'shopId' });

    useEffect(() => {
        onShopChange(field.value);
    }, [field.value, onShopChange]);

    return (
        <ReferenceInput
            {...field}
            source="shopId"
            reference="shops"
            label="Shop Name"
        >
            <AutocompleteInput
                optionText={(shopRecord) => `${shopRecord.shopUrlHostname} (${shopRecord.id})`}
                filterToQuery={(searchText) => ({ shopUrlHostname: searchText })}
                fullWidth
                helperText={false}
            />
        </ReferenceInput>
    );
};

const MarketplaceNameInput = () => {
    return (
        <ReferenceInput
            source="marketplaceId"
            reference="marketplaces"
            label="Marketplace"
        >
            <AutocompleteInput
                optionText={(marketplaceRecord) => `${marketplaceRecord.name} (${marketplaceRecord.id})`}
                filterToQuery={(searchText) => ({ name: searchText })}
                fullWidth
                helperText={false}
            />
        </ReferenceInput>
    );
};

const ShopLogo = ({ shopId }: { shopId: string | number }) => {
    const [logoUrl, setLogoUrl] = useState('');

    useEffect(() => {
        if (shopId) {
            const logoUrlFromService = `https://prosoom-shop-logos.s3.eu-central-1.amazonaws.com/${shopId}.webp`;
            axios
                .get(logoUrlFromService)
                .then(() => setLogoUrl(logoUrlFromService))
                .catch(() => setLogoUrl('/img_placeholder.jpg'));
        }
    }, [shopId]);

    return logoUrl ? (
        <img
            src={logoUrl}
            alt="Shop Logo"
            style={{ width: 100, height: 100, objectFit: 'contain' }}
        />
    ) : null;
};

export const ShopsMarketplaceEdit = () => {
    const record = useRecordContext();
    const [currentShopId, setCurrentShopId] = useState(record?.shopId || '');

    const handleShopChange = (shopId: string | number) => {
        setCurrentShopId(shopId);
    };

    return (
        <Edit title={<PageTitle />}>
            <SimpleForm>
                <ShopLogo shopId={currentShopId} />
                <TextInput
                    source="param"
                    label="Parameter"
                    fullWidth
                    helperText={false}
                />
                <ShopNameInput onShopChange={handleShopChange} />
                <MarketplaceNameInput />
                <BooleanInput
                    source="url_already_include_parameter"
                    label="URL Already Includes Parameter?"
                    fullWidth
                    helperText={false}
                />
            </SimpleForm>
        </Edit>
    );
};
