import React from 'react';
import {
	Edit,
	SaveButton,
	SimpleForm,
	TextInput,
	Toolbar
} from 'react-admin';

const CustomToolbar = (props: any) => (
	<Toolbar {...props}>
		<SaveButton disabled={props.pristine} />
	</Toolbar>
);

export const ClientEdit = (props: any) => {
	return (
		<Edit undoable={false} {...props}>
			<SimpleForm toolbar={<CustomToolbar />}>
				<TextInput disabled source='name' fullWidth helperText={false} />
				<TextInput source='location' fullWidth helperText={false}/>
				<TextInput source='responsible' fullWidth helperText={false}/>
				<TextInput disabled source='version' fullWidth helperText={false} />
				<TextInput disabled source='ip' label='IP' fullWidth helperText={false}/>
				<TextInput source='router' fullWidth helperText={false}/>
				<TextInput source='routerSpec' label='Router Spec' fullWidth helperText={false}/>
			</SimpleForm>
		</Edit>
	);
};
