import React from 'react';
import ReactJson from 'react-json-view';
import { useRecordContext } from 'react-admin';

function JSONViewer() {
	const record = useRecordContext();
	return (
		<ReactJson
			collapsed={1}
			src={JSON.parse(record['response'])}
			collapseStringsAfterLength={150}
			name={'response'}
		/>
	);
}

export default JSONViewer;
