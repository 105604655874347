import React from 'react';
import { FormControl, FormControlLabel, RadioGroup, Radio, styled } from '@mui/material';

interface HorizontalCheckboxGroupProps {
	selectedValue: string;
	onChange: (value: string) => void;
}

const HorizontalContainer = styled('div')({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	marginBottom: '20px',
});

const HorizontalCheckboxGroup: React.FC<HorizontalCheckboxGroupProps> = ({ selectedValue, onChange }) => {
	return (
		<HorizontalContainer>
			<FormControl component="fieldset">
				<RadioGroup
					row
					value={selectedValue}
					onChange={(event) => onChange(event.target.value)}
				>
					<FormControlLabel
						value="newManufacturer"
						control={<Radio />}
						label="Neuer Hersteller"
					/>
					<FormControlLabel
						value="existingManufacturer"
						control={<Radio />}
						label="Bestehender Hersteller"
					/>
				</RadioGroup>
			</FormControl>
		</HorizontalContainer>
	);
};

export default HorizontalCheckboxGroup;