import { List, Datagrid, TextField, useRecordContext, TopToolbar, CreateButton, Filter, TextInput, Pagination } from "react-admin";
import React, { useEffect, useState } from "react";
import axios from "axios";

const ShopLogo = (label: any) => {
    const record = useRecordContext();
    const [logoUrl, setLogoUrl] = useState('');

    useEffect(() => {
        if (record && record.shop) {
            const logoUrlFromService = `https://prosoom-shop-logos.s3.eu-central-1.amazonaws.com/${record['shopId']}.webp`;
            axios
                .get(logoUrlFromService)
                .then(() => setLogoUrl(logoUrlFromService))
                .catch(() => setLogoUrl(record.shop.shopLogoUrl ? record.shop.shopLogoUrl : '/img_placeholder.jpg'));
        }
    }, [record]);

    return logoUrl ? (
        <img
            src={logoUrl}
            alt="Shop Logo"
            style={{ width: 50, height: 50, objectFit: 'contain' }}
        />
    ) : null;
};

const ShopNameWithIdField = (label: any) => {
    const record = useRecordContext();
    return record ? (
        <span>
            {record.shop.shopUrlHostname} ({record.shopId})
        </span>
    ) : null;
};

const MarketplaceNameWithIdField = (label: any) => {
    const record = useRecordContext();
    return record ? (
        <span>
            {record.marketplace.name} ({record.marketplaceId})
        </span>
    ) : null;
};

const ListActions = (props: any) => (
    <TopToolbar>
        <CreateButton
            label="Hinzufügen"
            style={{ backgroundColor: 'green', color: 'white' }}
        />
    </TopToolbar>
);

const ShopsMarketplaceFilter = (props: any) => (
    <Filter {...props}>
        <TextInput label="Shop ID" source="shopId" alwaysOn resettable />
        <TextInput label="Shop Name" source="shop.shopUrlHostname" alwaysOn resettable />
        <TextInput label="Marketplace ID" source="marketplaceId" alwaysOn resettable />
        <TextInput label="Marketplace Name" source="marketplace.name" alwaysOn resettable />
    </Filter>
);

const ListPagination = (props: any) => <Pagination rowsPerPageOptions={[25, 50]} {...props} />;

export const ShopsMarketplaceList = (props: any) => (
    <List {...props} actions={<ListActions />} filters={<ShopsMarketplaceFilter />} perPage={25} pagination={<ListPagination />}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField source="id" label="ID" />
            <TextField source="param" label="Parameter" />
            <ShopLogo label="Logo"/>
            <ShopNameWithIdField label="Shop Name" />
            <MarketplaceNameWithIdField label="Marketplace" />
        </Datagrid>
    </List>
);
