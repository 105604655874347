import * as React from 'react';
import { useNotify, useRefresh } from 'react-admin';
import RefreshIcon from '@mui/icons-material/Refresh';
import axios from 'axios';
import { IconButton } from '@mui/material';

const Refresh = (props: any) => {
	const refresh = useRefresh();
	const notify = useNotify();

	const testShops = () => {
		axios
			.post(
				process.env.REACT_APP_HUB_API_URL + '/shops/update_statistic',
				{ id: props.data.id }	, { withCredentials: true }
			)
			.then((response) => {
				notify(response.data);
				refresh();
			})
			.catch((error) => {
				if (error.response.data.message) {
					notify(error.response.data.message);
				} else {
					notify(error.message);
				}
			});
	};

	return (
		<IconButton
			aria-label='refresh'
			sx={{ color: '#A9639E', padding: '0px' }}
			onClick={testShops}
		>
			<RefreshIcon />
		</IconButton>
	);
};

export default Refresh;
