import React from 'react';
import { Dialog, DialogContent, CircularProgress } from '@mui/material';

interface LoadingDialogProps {
	open: boolean;
}

const LoadingDialog: React.FC<LoadingDialogProps> = ({ open, ...props }) => {
	return (
		<Dialog
			open={open}
			aria-labelledby="loading-dialog"
			onClose={() => {}}
			disableEscapeKeyDown
			{...props}
		>
			<DialogContent>
				<CircularProgress />
			</DialogContent>
		</Dialog>
	);
};

export default LoadingDialog;
