import * as React from 'react';
import {
	Edit,
	FormTab,
	TabbedForm,
	TextInput,
	TextField,
	BooleanInput,
	RadioButtonGroupInput,
	Toolbar,
	SaveButton,
	Button,
	useRedirect,
	NumberInput,
	required,
	SelectInput
} from 'react-admin';
import ClearIcon from '@mui/icons-material/Clear';
import { pznTypes } from '../pznTypes';
import { Grid } from '@mui/material';
import SelectorsEdit from './SelectorsEdit';
import ExpectedValuesEdit from './ExpectedValuesEdit';
import { useFormContext } from 'react-hook-form';

const CustomToolbar = (props: any) => (
	<Toolbar {...props}>
		<SaveButton label='Speichern' />
		{/*<DeleteButton undoable={false} />*/}
		<CancelButton />
	</Toolbar>
);

const CancelButton = () => {
	const { getValues } = useFormContext();
	const { id, ...data } = getValues();
	const redirect = useRedirect();
	function cancel() {
		redirect(`/shops/${id}/show`);
	}
	return (
		<Button
			sx={{
				color: 'rgb(255,255,255)',
				backgroundColor: 'rgba(255,0,0,0.56)',
				padding: '7px',
				paddingRight: '16px',
				marginLeft: '10px'
			}}
			label='Abbrechen'
			onClick={cancel}
		>
			<ClearIcon sx={{ ml: 1 }} />
		</Button>
	);
};
const ShopsEdit = (props: any) => {
	return (
		<Edit {...props} redirect={'show'} mutationMode={'pessimistic'}>
			<TabbedForm
				toolbar={<CustomToolbar />}
				sx={{
					'& .MuiFormControl-root': { width: '100%' }
				}}
			>
				<FormTab label='Deatils'>
					<TextField source='id'/>
					<TextField source='shopUrlHostname'/>
					<BooleanInput helperText={false} source='status'/>
					<BooleanInput
						helperText={false}
						source='topShop'
						label={'Top Shop'}
					/>
					<RadioButtonGroupInput
						source='priority'
						choices={[
							{id: 1, name: 1},
							{id: 2, name: 2},
							{id: 3, name: 3},
							{id: 4, name: 4},
							{id: 5, name: 5},
							{id: 6, name: 6},
							{id: 7, name: 7},
							{id: 8, name: 8},
							{id: 9, name: 9},
							{id: 10, name: 10}
						]}
					/>
					<SelectInput
						helperText={false}
						source='pznType'
						label='PZN Type'
						validate={[required()]}
						choices={pznTypes}
					/>
					<NumberInput
						helperText={false}
						validate={[required()]}
						min={1}
						step={1}
						source='nextRetryIn'
						label='Next retry in (Days)'
					/>
					<TextInput
						helperText={false}
						validate={[required()]}
						label='Shop URL'
						source='shopUrl'
						type='url'
					/>
					<TextInput
						helperText={false}
						validate={[required()]}
						label='Product URL'
						source='productUrl'
						type='url'
					/>
					{/*                    <TextInput
                      
                      label="Shop Logo URL"
                      source="shopLogoUrl"
                    />*/}
					<TextInput
						label='Deny Parameter'
						source='denyParam'
						helperText='Bei mehreren Werten bitte so eingeben: xoid, random, key (* für ALLE Parameter)'
					/>
					<TextInput
						label='Deny Path'
						source='denyPath'
						helperText='Bei mehreren Werten bitte so eingeben: xoid, random, key'
					/>
					<BooleanInput
						helperText={false}
						source='removeHash'
						label='Remove # from URL'
					/>
					<h5 style={{marginBottom: "0px"}}>Affiliate Einstellungen</h5>
					<TextInput
						label='Affiliate Prefix URL'
						source='affiliatePrefix'
						helperText={false}
					/>
					<BooleanInput source='ownShopAffiliateOnly' label='Affiliate Links nur für "eigener Shop"' helperText={false}/>
					<h5 style={{marginBottom: "0px"}}>Notizen</h5>
					<TextInput
						helperText={false}
						rows='10'
						fullWidth
						label='Notes'
						multiline
						source='note'
					/>
					<h5 style={{marginBottom: "0px"}}>Weitere Informationen </h5>
					<NumberInput
						helperText={false}
						min={0}
						step={0.01}
						source='shippingCost'
						label='Versandkosten'
					/>
					<NumberInput
						helperText={false}
						min={0}
						step={0.01}
						source='freeShippingFrom'
						label='Versandkostenfrei ab'
					/>
					<NumberInput
						helperText={false}
						min={0}
						step={0.01}
						source='courierCosts'
						label='Botendienst/Kurierdienst Kosten'
					/>
				</FormTab>
				<FormTab label='Selektoren & Erwartete Werte'>
					<Grid container spacing={2}>
						<Grid
							item
							xs={6}
							sx={{ borderRight: '2px solid', pr: 2 }}
						>
							<SelectorsEdit />
						</Grid>
						<Grid item xs={6}>
							<ExpectedValuesEdit />
						</Grid>
					</Grid>
				</FormTab>
				<FormTab label='Onsite Search'>
					<BooleanInput
						source='onsiteSearchStatus'
						label='Onsite Search Crawling aktiv'
					/>
					<TextInput
						label='Search Url'
						source='onsiteSearchUrl'
						helperText={
							'__SEARCHTERM__ als Platzhalter für den Suchbegriff eingeben. OHNE HOSTNAMEN z.B. https://www.docmorris.de/search?query=aspirin -> /search?query=__SEARCHTERM__'
						}
					/>
					<TextInput
						label='Selektor'
						source='selectorOnsiteSearch'
						helperText={
							'Selektor für den Link zum Produkt. href-Attribut ist nicht notwendig.'
						}
					/>
					<TextInput
						label='Methode'
						source='onsiteSearchMethod'
						helperText={
							'Für Curl Crawler curl eintragen. Für Headless Browser selenium. Sonst leer lassen.'
						}
					/>
				</FormTab>
			</TabbedForm>
		</Edit>
	);
};

export default ShopsEdit;
