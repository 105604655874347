import * as React from 'react';
import { AppBar } from 'react-admin';
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

interface IUserProfile {
	nickname: string;
	name: string;
	picture: string;
	email: string;
}

const MyAppBar = (props: any) => {
	const [userName, setUserName] = useState('user');
	useEffect(() => {
		const user: IUserProfile = JSON.parse(
			localStorage.getItem('userProfile') as string
		);
		if (user) {
			setUserName(user.name);
		}
	}, []);

	return (
		<AppBar {...props}>
			<Typography
				variant='h6'
				color='inherit'
				sx={{
					flex: 1,
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					overflow: 'hidden'
				}}
				id='react-admin-title'
			/>
			<Typography
				variant='body1'
				gutterBottom
				sx={{ display: 'flex', order: 1, margin: 0 }}
			>
				{userName}
			</Typography>
		</AppBar>
	);
};

export default MyAppBar;
