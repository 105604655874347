import React from 'react';
import { Checkbox, List, ListItem, ListItemText, styled } from '@mui/material';

interface Synonym {
	id: number;
	synonym: string;
	valid: boolean;
}

interface SynonymListProps {
	synonyms: Synonym[] | null;
	onCheckboxChange: (id: number) => void;
}

const SynonymContainer = styled('div')({
	width: '300px',
	maxHeight: '100vh',
	overflowY: 'auto',
	padding: '20px',
	borderLeft: '1px solid #ddd',
	backgroundColor: '#f9f9f9',
});

const SynonymListItem = styled(ListItem)({
	display: 'flex',
	alignItems: 'center',
	marginBottom: '10px',
});

const SynonymList: React.FC<SynonymListProps> = ({ synonyms, onCheckboxChange }) => {
	return (
		<SynonymContainer>
			<List>
				{synonyms ? synonyms.map(synonym => (
					<SynonymListItem key={synonym.id} dense>
						<Checkbox
							edge="start"
							checked={synonym.valid}
							tabIndex={-1}
							disableRipple
							inputProps={{ 'aria-labelledby': `checkbox-list-label-${synonym.id}` }}
							onChange={() => onCheckboxChange(synonym.id)}
						/>
						<ListItemText id={`checkbox-list-label-${synonym.id}`} primary={synonym.synonym} />
					</SynonymListItem>
				)) : null}
			</List>
		</SynonymContainer>
	);
};

export default SynonymList;