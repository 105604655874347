import * as React from 'react';
import StoreIcon from '@mui/icons-material/Store';
import { DataTable } from './DataTable';
import { Card, CardContent, Grid, Typography } from '@mui/material';

function ShopsCard(props: any) {
	return (
		<Grid
			item
			xs={12}
			sx={{
				position: 'relative',
				marginTop: '25px'
			}}
		>
			<StoreIcon
				className={'iconDashboard'}
				style={{ background: '#396CBD' }}
			/>
			<Card sx={{ paddingBottom: '10px' }}>
				<CardContent>
					<Typography
						color={'textSecondary'}
						variant='h4'
						align='center'
					>
						Shops
					</Typography>
					<DataTable data={props.data} />
				</CardContent>
			</Card>
		</Grid>
	);
}

export default ShopsCard;
