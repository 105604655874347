import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';

interface ImageOrPlaceholderProps {
	src?: string; // Optionaler Bild-Link
	alt?: string; // Optionaler Alt-Text
}

const ImageOrPlaceholderGrid: React.FC<ImageOrPlaceholderProps> = ({
																   src,
																   alt = 'Image',
															   }) => {
	const [imageExists, setImageExists] = useState(true);
	const [loading, setLoading] = useState(false); // Zustand für die Ladeanimation

	useEffect(() => {
		if (src) {
			setLoading(true); // Ladeanimation starten
			const img = new Image();
			img.src = src;

			img.onload = () => {
				setImageExists(true);
				setLoading(false);
			};

			img.onerror = () => {
				setImageExists(false);
				setLoading(false);
			};
		}
	}, [src]);

	if (!imageExists || !src) {
		return (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: '#eee',
					border: '1px solid #ccc',
					width: '100%',
					height: '100%',
					paddingBottom: '100%',
					position: 'relative'
				}}
			>
				No Image
			</Box>
		);
	}

	if (loading) {
		return (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: '#eee',
					border: '1px solid #ccc',
					width: '100%',
					height: '100%',
					paddingBottom: '100%',
					position: 'relative'
				}}
			>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Box
			component="img"
			src={src}
			alt={alt}
			sx={{
				width: '100%',
				height: '100%',
				objectFit: 'contain',
				position: 'absolute',
				top: 0,
				left: 0,
			}}
		/>
	);
};

export default ImageOrPlaceholderGrid;
