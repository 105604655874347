import * as React from 'react';
import Refresh from '../../btn/one/Refresh';
import { CircularProgressWithLabel } from './CircularProgressWithLabel';
import { Card, CardContent, Divider, Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';

export const ShopsShowAside = () => {
	const record = useRecordContext();
	// @ts-ignore
	return (
		<Card style={{ minWidth: 250, marginLeft: '10px' }}>
			<CardContent>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'end'
					}}
				>
					<Typography variant='h6' style={{ float: 'left' }}>
						Statistic
					</Typography>
					<Refresh data={record} />
				</div>
				<Divider style={{ marginBottom: '5px' }} />
				{record && (
					<div>
						{/*                        <Typography variant="caption" style={{ color: 'grey' }}>
                            Jobs
                        </Typography>
                        <Typography variant="body2" style={{ marginBottom: '10px' }}>
                            {record.jobs}
                        </Typography>*/}
						<Typography variant='caption' color='textSecondary'>
							Urls
						</Typography>
						<Typography
							variant='body2'
							style={{ marginBottom: '10px' }}
						>
							{record.urls}
						</Typography>
						{/*                        <Typography variant="caption" color="textSecondary">
                            Products
                        </Typography>
                        <Typography variant="body2" style={{ marginBottom: '10px' }}>
                            {record.products}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            Letzer Produkt Import
                        </Typography>
                        <Typography variant="body2" style={{ marginBottom: '10px' }}>
                            {record.lastProductImport ? new Date(Date.parse(record.lastProductImport)).toLocaleString() : 'Noch kein Produkt'}
                        </Typography>*/}
						<div style={{ marginBottom: '10px' }}>
							<Typography
								variant='caption'
								color='textSecondary'
								display='block'
								gutterBottom={true}
							>
								Erforderliche Felder
							</Typography>
							<CircularProgressWithLabel
								record={record}
								source='matchRequiredFields'
							/>
						</div>
						<div style={{ marginBottom: '10px' }}>
							<Typography
								variant='caption'
								color='textSecondary'
								display='block'
								gutterBottom={true}
							>
								Optionale Felder
							</Typography>
							<CircularProgressWithLabel
								record={record}
								source='matchOptionalFields'
							/>
						</div>
					</div>
				)}
			</CardContent>
		</Card>
	);
};
