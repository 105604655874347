import * as React from 'react';
import {
	Box,
	Typography,
	Button,
	Checkbox,
	FormControlLabel,
	TextField,
	Grid,
	Paper,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ImageOrPlaceholder from '../../utils/ImageOrPlaceholder';
import axios from 'axios';
import { useEffect, useState } from 'react';
import ItemTranslationTypography from '../../utils/ItemTranslationTypography';

const ItemTranslation: React.FC = () => {
	const [item, setItem] = useState<{ id: number, itemNameOld: string, itemId: number, pznDe: string, pznAt: string, gtin8: string, gtin12: string, gtin13: string, gtin14: string, asin: string, isbn: string, companyId: number, manufacturer: string, languageId: string, articleNumber: string} | null>(null);
	const [newTranslation, setNewTranslation] = useState<string>('');
	const [overwriteTranslation, setOverwriteTranslation] = useState<boolean>(false);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		fetchNextItem();
	}, []);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleTranslationProcess = (instruction: string) => {
		if (!item) return;

		const result = {
			id: item.id,
			itemId: item.itemId,
			companyId: item.companyId,
			itemNameOld: item.itemNameOld,
			itemNameSuggested: newTranslation,
			languageId: item.languageId,
			articleNumber: item.articleNumber,
			pznDe: item.pznDe,
			pznAt: item.pznAt,
			gtin8: item.gtin8,
			gtin12: item.gtin12,
			gtin13: item.gtin13,
			gtin14: item.gtin14,
			isbn: item.isbn,
			asin: item.asin,
			override: overwriteTranslation,
			instruction,
		};

		axios.post(process.env.REACT_APP_HUB_API_URL + '/translation/process-suggested', result, {withCredentials: true})
			.then(response => {
				fetchNextItem();
			})
			.catch(error => {
				console.error(`Error processing item with instruction "${instruction}":`, error);
			});
	};

	const fetchNextItem = () => {
		axios.get(process.env.REACT_APP_HUB_API_URL + '/translation/get-next-translation', {withCredentials: true})
			.then(response => {
				setItem(response.data);
				setNewTranslation(response.data?.itemNameSuggested ? response.data.itemNameSuggested : '');
				setOverwriteTranslation(false);
			})
			.catch(error => {
				console.error('Error fetching the next item:', error);
			});
	};

	const handleMarkProcessed = () => handleTranslationProcess('take');
	const handleNewItem = () => handleTranslationProcess('create');
	const handleRemoveItem = () => handleTranslationProcess('remove');

	return (
		<Grid sx={{
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			gap: 2,
			padding: 2,
			height: '100vh',
		}}>
			<Box width={'80%'}>
				<Typography variant="h4" sx={{ textAlign: 'center' }}>{item?.itemNameOld ? item.itemNameOld : 'Leer'}</Typography>

				<Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
					<ImageOrPlaceholder src={item ? 'https://product-images.prosoom.com/pharmacies/' + item.itemId + '_big.webp' : ''} height={300} alt="Img" />
				</Box>

				<Paper
					sx={{
						marginTop: 1, // Abstand über der Umrandung
						padding: 1, // Padding innerhalb der Umrandung
						border: '1px solid #ccc', // Umrandung
						width: 'auto', // Passt sich an den Inhalt an
					}}
				>
					<Typography variant="h6" sx={{ textAlign: 'center', marginBottom: 1 }}>Basisinformationen</Typography>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Typography variant="body1" sx={{ textAlign: 'center' }}>Hersteller: {item?.manufacturer ? item.manufacturer : 'Loading...'}</Typography>
						</Grid>

						<Grid item xs={6}>
							<Typography variant="body1" sx={{ textAlign: 'center' }}>Item ID: {item?.itemId ? item.itemId : 'Loading...'}</Typography>
						</Grid>
					</Grid>

					<Typography variant="h6" sx={{ textAlign: 'center', marginBottom: 1 }}>Artikelnummern</Typography>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Typography variant="body1" sx={{ textAlign: 'center' }}>PZN_DE: {item?.pznDe? item.pznDe : 'Leer'}</Typography>
							<Typography variant="body1" sx={{ textAlign: 'center' }}>PZN_AT: {item?.pznAt ? item.pznAt : 'Leer'}</Typography>
							<Typography variant="body1" sx={{ textAlign: 'center' }}>GTIN8: {item?.gtin8 ? item.gtin8 : 'Leer'}</Typography>
							<Typography variant="body1" sx={{ textAlign: 'center' }}>GTIN12: {item?.gtin12 ? item.gtin12 : 'Leer'}</Typography>
						</Grid>

						<Grid item xs={6}>
							<Typography variant="body1" sx={{ textAlign: 'center' }}>GTIN13: {item?.gtin13 ? item.gtin13 : 'Leer'}</Typography>
							<Typography variant="body1" sx={{ textAlign: 'center' }}>GTIN14: {item?.gtin14 ? item.gtin14 : 'Leer'}</Typography>
							<Typography variant="body1" sx={{ textAlign: 'center' }}>ISBN: {item?.isbn ? item.isbn : 'Leer'}</Typography>
							<Typography variant="body1" sx={{ textAlign: 'center' }}>ASIN: {item?.asin ? item.asin : 'Leer'}</Typography>
						</Grid>
					</Grid>
				</Paper>
				<ItemTranslationTypography languageId={item?.languageId} itemNameOld={item?.itemNameOld} />
				<TextField
					label={item?.languageId ? `Neue Translation (${item.languageId})` : 'Neue Translation'}
					value={newTranslation}
					onChange={(e) => setNewTranslation(e.target.value)}
					fullWidth
				/>
			</Box>
			<FormControlLabel
				label="Standard Translation überschreiben"
				control={<Checkbox checked={overwriteTranslation} onChange={(e) => setOverwriteTranslation(e.target.checked)} />}
			/>

			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>{"Item Translation Erklärung"}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						In diesem Frontend werden neue Item Translations für bestehende Items angezeigt.
						Oben sieht man das Produktbild sowie die Informationen zum Item. Darunter wird die
						bisherige Item Translation (Item Name in unserem System) anzeigt mit dem entsprechenden
						Länder Code innerhalb der Klammern. Darunter steht in einem Text Feld der neue vorgeschlagene
						Name. Diesen kann man bei evtl. kleineren Fehlern z.B. falsche Sonderzeichen oder Doppelungen
						noch anpassen, er sollte aber so gut wie unverändert bleiben. Passt die Translation nicht zu
						diesem Item handelt es sich um ein neues Item, dann drückt man den Button "NEUES ITEM". Ist
						die Translation vollkommen falsch (z.B. HTML Tag oder Ähnliches) löscht man diese mit dem Button
						"TRANSLATION LÖSCHEN". Passt die Translation hat man zwei Möglichkeiten. Ist die Translation besser
						(mehr Informationen, weniger Abkürzungen, ...) als die bisherige klickt man die Checkbox
						"Standard Translation überschrieben" an und anschließend den Button "TRANSLATION ÜBERNEHMEN".
						Ist der vorherige Name besser so drückt man nur den Button "TRANSLATION ÜBERNEHMEN".
						Viel Spaß :)
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary" autoFocus>
						Schließen
					</Button>
				</DialogActions>
			</Dialog>

			<Grid container sx={{
				width: '80%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
				<Button variant="contained" startIcon={<InfoIcon />} sx={{
					padding: '8px 16px',
					margin: '4px',
					marginRight: 'auto',
				}} onClick={handleClickOpen}>Hilfe</Button>
				<Box sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flex: '1 0 auto',
				}}>
					<Button variant="contained" startIcon={<DeleteIcon />} sx={{
						padding: '8px 16px',
						margin: '4px',
					}} onClick={handleRemoveItem}>Translation Löschen</Button>

					<Button variant="contained" endIcon={<AddCircleIcon />} sx={{
						padding: '8px 16px',
						margin: '4px',
					}} onClick={handleNewItem}>Neues Item</Button>

					<Button variant="contained" endIcon={<CheckCircleIcon />} sx={{
						padding: '8px 16px',
						margin: '4px',
					}} onClick={handleMarkProcessed}>Translation übernehmen</Button>
				</Box>
			</Grid>
		</Grid>
	);
};

export default ItemTranslation;