export const calculate7DayAvg = (speedTests: any[]) => {
    const now = new Date();
    const last7Days = speedTests.filter(test => {
        const testDate = new Date(test.createdAt);
        return (now.getTime() - testDate.getTime()) <= 7 * 24 * 60 * 60 * 1000;
    });

    if (last7Days.length === 0) return 0;

    const totalSpeed = last7Days.reduce((sum, test) => sum + test.speed, 0);
    return (totalSpeed / last7Days.length).toFixed(2);
};

export const filterLast2Days = (speedTests: any[]) => {
    const now = new Date();
    return speedTests.filter(test => {
        const testDate = new Date(test.createdAt);
        return (now.getTime() - testDate.getTime()) <= 2 * 24 * 60 * 60 * 1000;
    });
};

export const calculate7DayAvgPerDay = (speedTests: any[]) => {
    const now = new Date();
    const last7Days = [];

    for (let i = 0; i < 7; i++) {
        const dayStart = new Date(now);
        dayStart.setDate(now.getDate() - i);
        dayStart.setHours(0, 0, 0, 0);

        const dayEnd = new Date(dayStart);
        dayEnd.setHours(23, 59, 59, 999);

        const daySpeedTests = speedTests.filter(test => {
            const testDate = new Date(test.createdAt);
            return testDate >= dayStart && testDate <= dayEnd;
        });

        const avgSpeed = daySpeedTests.reduce((sum, test) => sum + test.speed, 0) / (daySpeedTests.length || 1);

        last7Days.push({
            date: dayStart.toLocaleDateString(),
            avgSpeed: avgSpeed.toFixed(2),
        });
    }

    return last7Days.reverse();
};