import * as React from 'react';
import PropTypes from 'prop-types';
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';
import { Tooltip, Typography } from '@mui/material';

const NoteField = ({ record = {} }: { record: any }) => {
	if (!record['note']) {
		return <SpeakerNotesOutlinedIcon sx={{ color: '#9e9e9e' }} />;
	}
	return (
		<Tooltip
			title={
				<Typography variant='subtitle1' color='inherit'>
					{record['note']}
				</Typography>
			}
			placement='right'
		>
			<SpeakerNotesOutlinedIcon sx={{ color: '#F09427' }} />
		</Tooltip>
	);
};

NoteField.propTypes = {
	label: PropTypes.string,
	record: PropTypes.object
};

export default NoteField;
