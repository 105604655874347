import * as React from 'react';
import { Fragment, cloneElement } from 'react';
import {
	List,
	Datagrid,
	TextField,
	UrlField,
	Filter,
	TextInput,
	SelectInput,
	TopToolbar,
	CreateButton,
	Pagination,
	sanitizeListRestProps,
	FunctionField
} from 'react-admin';
import StartShops from '../btn/bulk/StartShops';
import StopShopsButton from '../btn/bulk/StopShops';
import TestAllShops from '../btn/bulk/TestAllShops';
import SetSelectorTemplate from '../btn/bulk/SetSelectorTemplate';
import NoteField from './NoteField';
import { CircularProgressWithLabel } from './show/CircularProgressWithLabel';
import { GoToID } from './show/GoToID';
import { pznTypes } from './pznTypes';
import { BooleanField } from 'react-admin';

const ShopsListActions: any = () => (
	<TopToolbar>
		<CreateButton
			label={'Neuen Shop anlegen'}
			size={'large'}
			alignIcon={'right'}
			style={{ textTransform: 'unset' }}
		/>
	</TopToolbar>
);

ShopsListActions.defaultProps = {
	selectedIds: []
};

const ShopsFilter = (props: any) => {
	return (
		<Filter {...props}>
			<GoToID alwaysOn />
			<SelectInput
				source='pznType'
				label='PZN Type'
				choices={pznTypes}
				alwaysOn
			/>
			<TextInput label='Shop URL' source='shopUrl' alwaysOn />
			<TextInput label='Notes' source='note' alwaysOn />
			<SelectInput
				label='Status'
				source='status'
				choices={[
					{ id: 1, name: 'Aktiv' },
					{ id: 0, name: 'Inaktiv' }
				]}
				alwaysOn
			/>
			<SelectInput
				label='Top Shop'
				source='topShop'
				choices={[
					{ id: 1, name: 'Ja' },
					{ id: 0, name: 'Nein' }
				]}
				alwaysOn
			/>
			<SelectInput
				label='Priority'
				source='priority'
				choices={[
					{ id: '1', name: 1 },
					{ id: '2', name: 2 },
					{ id: '3', name: 3 },
					{ id: '4', name: 4 },
					{ id: '5', name: 5 },
					{ id: '6', name: 6 },
					{ id: '7', name: 7 },
					{ id: '8', name: 8 },
					{ id: '9', name: 9 },
					{ id: '10', name: 10 }
				]}
				alwaysOn
			/>
		</Filter>
	);
};

const ShopsPagination = (props: any) => (
	<Pagination rowsPerPageOptions={[50, 100, 250, 500]} {...props} />
);

const ShopsBulkActionButtons = (props: any) => (
	<Fragment>
		<TestAllShops {...props} />
		<SetSelectorTemplate {...props} />
		<StartShops {...props} />
		<StopShopsButton {...props} />
	</Fragment>
);

const ShopsRowStyle = (record: any) => ({
	borderLeftColor: record.status === true ? '#7BC656' : '#EF4338',
	borderLeftWidth: '15px',
	borderLeftStyle: 'solid'
});

const ShopsList = (props: any) => {
	return (
		<List
			{...props}
			filters={<ShopsFilter />}
			actions={<ShopsListActions />}
			perPage={50}
			pagination={<ShopsPagination />}
		>
			<Datagrid
				rowClick='show'
				rowStyle={ShopsRowStyle}
				bulkActionButtons={false}
			>
				<TextField
					label='ID'
					source='id'
					style={{ fontWeight: 'bold' }}
				/>
				<FunctionField
					label='Notes'
					render={(record: any) => <NoteField record={record} />}
				/>
				<TextField
					source='shopUrl'
					label='Shop-URL'
					style={{ fontWeight: 'bold' }}
				/>
				<BooleanField source='topShop' label='Top Shop' />
				{/*<UrlField target="_blank" label="Shop-URL" source="shopUrl" type="url" />*/}
				<TextField source='priority' />
				<TextField label='Shop-System' source='shopSystem' />
				<TextField label='Urls' source='urls' />
				{/*                <TextField label="Products" source="products" />*/}
				{/*<DateField label="Last-Product-Crawled" source="lastProductImport" showTime />*/}
				<FunctionField
					label='Match Required Fields'
					render={(record: any) => (
						<CircularProgressWithLabel
							record={record}
							source='matchRequiredFields'
							{...props}
						/>
					)}
				/>{' '}
				<FunctionField
					label='Match Optional Fields'
					render={(record: any) => (
						<CircularProgressWithLabel
							record={record}
							source='matchOptionalFields'
							{...props}
						/>
					)}
				/>
			</Datagrid>
		</List>
	);
};
export default ShopsList;
